import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Typography, Alert } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import TemplateEditor from '../Cohort/TemplateEditor';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

const DiscussionForm = ({ id = undefined }: any) => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [currentAvailableFrom, setCurrentAvailableFrom] = useState<Moment | null>(moment());
	const [currentUntil, setCurrentUntil] = useState<Moment | null>(moment().add(5, 'day'));
	const navigate = useNavigate();
	const [error, setError] = useState('');

	const handleAvailableFromChange = (newValue: Moment | null) => {
		setCurrentAvailableFrom(newValue);
		if (currentUntil && newValue && newValue > currentUntil) {
			setError('Until date must be after Available From date.');
		} else {
			setError('');
		}
	};

	const handleUntilChange = (newValue: Moment | null) => {
		setCurrentUntil(newValue);
		if (currentAvailableFrom && newValue && newValue < currentAvailableFrom) {
			setError('Until date must be after Available From date.');
		} else {
			setError('');
		}
	};

	useEffect(() => {
		if (id) {
			axios
				.get(`${baseUrl}/api/admin/get-discussion/${id}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(res => {
					if (res.data.success) {
						setTitle(res.data.discussion.title);
						setDescription(res.data.discussion.description);
						setCurrentAvailableFrom(moment(res.data.discussion.from));
						setCurrentUntil(moment(res.data.discussion.until));
					}
				});
		}
	}, [id]);

	const handleSave = async (isPublished = false) => {
		const dataToSave: any = {
			title,
			description,
			from: currentAvailableFrom,
			until: currentUntil,
			isPublished
		};

		if (id) {
			dataToSave['id'] = id;
		}

		const data = await axios.post(`${baseUrl}/api/admin/save-discussion`, dataToSave, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		if (data.data.success) {
			navigate('/discussions');
		}
	};

	return (
		<Box sx={{ padding: 2 }}>
			{/* Form Header */}
			<Link
				style={{
					color: '#1F72C5',
					textDecoration: 'none'
				}}
				to="/discussions"
			>
				<Typography
					sx={{
						fontSize: '16pt',
						color: '#1F72C5',
						textAlign: 'left'
					}}
				>
					<ArrowBackIcon sx={{ mr: 2 }} />
					Back to discussion dashboard
				</Typography>
			</Link>

			{/* Form Fields */}
			<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 4 }}>
				Discussion Title
			</Typography>
			<TextField
				fullWidth
				variant="outlined"
				placeholder="Discussion Title"
				value={title}
				onChange={e => setTitle(e.target.value)}
				sx={{ marginBottom: 2 }}
			/>

			<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
				Description
			</Typography>

			<TemplateEditor style={{ height: '300px' }} value={description} setValue={setDescription} />
			{/* <ReactQuill value={description} style={{ height: '300px' }} onChange={setDescription} /> */}

			<div style={{ width: '100%' }}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<Box sx={{ marginTop: 8, display: 'flex', width: '100%', gap: 2 }}>
						<div style={{ width: '100%' }}>
							<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
								Available From
							</Typography>
							<DateTimePicker
								label="Available From"
								value={currentAvailableFrom}
								onChange={handleAvailableFromChange}
								sx={{ marginBottom: 2, marginRight: 2, width: '100%' }}
							/>
						</div>
						<div style={{ width: '100%' }}>
							<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
								Until
							</Typography>
							<DateTimePicker
								label="Until"
								value={currentUntil}
								onChange={handleUntilChange}
								sx={{ marginBottom: 2, width: '100%' }}
							/>
							{error && (
								<Alert severity="error" sx={{ marginTop: 1 }}>
									{error}
								</Alert>
							)}
						</div>
					</Box>
				</LocalizationProvider>
			</div>

			<Box sx={{ marginTop: 5, marginBottom: 2, border: '1px solid Gray' }}></Box>

			<Box sx={{ marginTop: 2, marginBottom: 2 }}>
				<Button
					disabled={!title || title?.length === 0 || !description || description?.length === 0 || error?.length > 0}
					variant="contained"
					onClick={() => handleSave(true)}
				>
					{id ? 'Update and publish' : 'Save and publish'}
				</Button>
				<Button
					disabled={!title || title?.length === 0 || !description || description?.length === 0 || error?.length > 0}
					variant="outlined"
					sx={{ marginLeft: 2 }}
					onClick={() => handleSave(false)}
				>
					{id ? 'Update as draft' : 'Save'}
				</Button>
			</Box>
		</Box>
	);
};

export default DiscussionForm;
