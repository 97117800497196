import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import './AnalyzeMeetings.css';
import { useNavigate } from 'react-router-dom';

const UploadLanding = () => {
	const navigate = useNavigate();

	const styles = {
		MainBox: {
			height: 'calc(100vh - 70px)'
		},
		Box: {
			padding: '32px',
			margin: 'auto',
			gap: '16px',
			display: 'flex'
		},
		Header: {
			fontSize: '24px',
			fontWeight: '700',
			mb: '18px'
		},
		Button: {
			height: '50px',
			borderRadius: '100px',
			p: '4px 20px',
			fontSize: '18px',
			fontWeight: 500,
			width: '50%',
			textTransform: 'capitalize',
			'@media (max-width: 1600px)': {
				fontSize: '15px'
			}
		}
	};

	return (
		<Box sx={{ ...styles.MainBox }}>
			<Box sx={{ ...styles.Box }}>
				<div style={{ width: '70%' }}>
					<Typography sx={{ ...styles.Header }}>Upload Meeting Files </Typography>
					<Box sx={{ fontSize: '14px', width: '90%' }}>
						How do you show up in real meetings? please take a moment to upload any a meeting record related to the
						topic you&apos;d like to discuss. Meeting data offers more accurate feedback. Simply click the upload button
						to get started, and let&apos;s embark on an insightful discussion together!
					</Box>
				</div>
				<div style={{ width: '30%', display: 'flex', justifyContent: 'end' }}>
					<Button
						variant="outlined"
						sx={{ ...styles.Button }}
						onClick={() => {
							navigate('/analyze-meetings?tab=upload');
						}}
					>
						Upload
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path
								d="M9.31002 6.71002C9.21732 6.80254 9.14377 6.91242 9.09359 7.0334C9.04341 7.15437 9.01758 7.28405 9.01758 7.41502C9.01758 7.54599 9.04341 7.67567 9.09359 7.79665C9.14377 7.91762 9.21732 8.02751 9.31002 8.12002L13.19 12L9.31002 15.88C9.12304 16.067 9.018 16.3206 9.018 16.585C9.018 16.8494 9.12304 17.103 9.31002 17.29C9.497 17.477 9.7506 17.582 10.015 17.582C10.2794 17.582 10.533 17.477 10.72 17.29L15.31 12.7C15.4027 12.6075 15.4763 12.4976 15.5265 12.3766C15.5766 12.2557 15.6025 12.126 15.6025 11.995C15.6025 11.8641 15.5766 11.7344 15.5265 11.6134C15.4763 11.4924 15.4027 11.3825 15.31 11.29L10.72 6.70002C10.34 6.32002 9.70002 6.32002 9.31002 6.71002Z"
								fill="#1976d2"
							/>
							<path
								d="M17.31 6.71002C17.2173 6.80254 17.1438 6.91242 17.0936 7.0334C17.0434 7.15437 17.0176 7.28405 17.0176 7.41502C17.0176 7.54599 17.0434 7.67567 17.0936 7.79665C17.1438 7.91762 17.2173 8.02751 17.31 8.12002L21.19 12L17.31 15.88C17.123 16.067 17.018 16.3206 17.018 16.585C17.018 16.8494 17.123 17.103 17.31 17.29C17.497 17.477 17.7506 17.582 18.015 17.582C18.2794 17.582 18.533 17.477 18.72 17.29L23.31 12.7C23.4027 12.6075 23.4763 12.4976 23.5265 12.3766C23.5766 12.2557 23.6025 12.126 23.6025 11.995C23.6025 11.8641 23.5766 11.7344 23.5265 11.6134C23.4763 11.4924 23.4027 11.3825 23.31 11.29L18.72 6.70002C18.34 6.32002 17.7 6.32002 17.31 6.71002Z"
								fill="#1976d2"
							/>
						</svg>
					</Button>
				</div>
			</Box>
		</Box>
	);
};

export default UploadLanding;
