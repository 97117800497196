import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../../config/firebase';

interface IUsersSummaryData {
	totalUsers: number;
	totalCompletedSimulations: number;
	totalCoachSessions: number;
	totalTime: number;
}

const defaultUsersSummaryData: IUsersSummaryData = {
	totalUsers: 0,
	totalCompletedSimulations: 0,
	totalCoachSessions: 0,
	totalTime: 0
};

const UsersSummary = () => {
	const [usersSummaryData, setUsersSummaryData] = useState<IUsersSummaryData>(defaultUsersSummaryData);

	const getUsersSummaryData = async () => {
		try {
			const q = query(collection(db, 'overallSummary'));
			const summaryDocSnap = await getDocs(q);
			const summaryResults = summaryDocSnap?.docs;
			const summary = summaryResults.map(doc => doc.data() as IUsersSummaryData);
			setUsersSummaryData(summary[0] || defaultUsersSummaryData);
		} catch (error) {
			console.error('Error fetching summary data from Firestore:', error);
		}
	};

	useEffect(() => {
		getUsersSummaryData();
	}, []);

	return (
		<Box
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '32px',
				fontFamily: 'IBM Plex Sans',
				textAlign: 'center'
			}}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box>
					<Typography sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>Overall Summary</Typography>
				</Box>
			</Box>
			<Box>
				{usersSummaryData && Object.keys(usersSummaryData).length > 0 && (
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}>
								<TableRow sx={{ textWrap: 'nowrap' }}>
									<TableCell align="left">Total User #</TableCell>
									<TableCell align="left">Total Simulations Completed</TableCell>
									<TableCell align="left">Total Coach Sessions</TableCell>
									<TableCell align="left">Total Time (min)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
									<TableCell align="left">{usersSummaryData.totalUsers}</TableCell>
									<TableCell align="left">{usersSummaryData.totalCompletedSimulations}</TableCell>
									<TableCell align="left">{usersSummaryData.totalCoachSessions}</TableCell>
									<TableCell align="left">{Math.round(usersSummaryData.totalTime / 1000 / 60)}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Box>
		</Box>
	);
};

export default UsersSummary;
