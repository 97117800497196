import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

export const timeCode = [
	{ code: 1, name: 'All', endPoint: 'allgraph' },
	{ code: 2, name: 'Month', endPoint: 'graphYear' },
	{ code: 3, name: 'Week', endPoint: 'allweekGraph' }
	// { code: 4, name: 'Day', endPoint: 'allDay' },
	// { code: 5, name: 'Session', endPoint: 'singleSessionGraph' },
	// { code: 6, name: new Date().toLocaleString('default', { month: 'long' }), endPoint: 'allcurrent-month' }
];

export const conversationCategoryTab = [
	{ code: 1, name: 'communication' },
	{ code: 2, name: 'coaching' }
];

export const otherDimension = [
	'Identify mutual purpose or shared goal',
	'Reveal the purpose behind their strategy',
	'Share your good intentions',
	'Show mutual Respect'
];

export const coachingDimension = ['Inquiring', 'Encouraging', 'Summarizing', 'Reflecting'];

export const strokeColor = [
	'#2d9bdb',
	'#f9c64f',
	'#084b71',
	'#d55906',
	'#9b59b6',
	'#16a085',
	'#e74c3c',
	'#34495e',
	'#2980b9',
	'#f39c12',
	'#8e44ad',
	'#27ae60',
	'#d35400',
	'#c0392b',
	'#bdc3c7',
	'#7f8c8d',
	'#1abc9c',
	'#2ecc71',
	'#3498db',
	'#9b59b6'
];

export const fillColor = [
	'#2d9bdb21',
	'#f9c64f1d',
	'#084b712a',
	'#d559062c',
	'#9b59b621',
	'#16a08521',
	'#e74c3c21',
	'#34495e21',
	'#2980b921',
	'#f39c1221',
	'#8e44ad21',
	'#27ae6021',
	'#d3540021',
	'#c0392b21',
	'#bdc3c721',
	'#7f8c8d21',
	'#1abc9c21',
	'#2ecc7121',
	'#3498db21',
	'#9b59b621'
];

interface AreaGraphProps {
	code: number;
	conversationCategoryCode: number;
}

const AreaGraph: React.FC<AreaGraphProps> = ({ code, conversationCategoryCode }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const [allData, setAlldata] = useState<any[]>([]);

	useEffect(() => {
		callGraph(code, conversationCategoryCode);
	}, [code, conversationCategoryCode]);

	const callGraph = async (codeNumber: number, conversationCategoryCode: number) => {
		const urlConfig = timeCode.find(item => item.code === codeNumber);
		if (urlConfig) {
			try {
				const response = await axios.get(
					`${baseUrl}/${urlConfig.endPoint}/${selectedUserId}/${conversationCategoryCode}`,
					{
						headers: {
							Authorization: token
						}
					}
				);
				if (response.data.success) {
					const transformedData = transformData(response.data.data || []);
					setAlldata(transformedData);
					// console.log(transformedData);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};
	// Function to transform data values to range 25-100
	const transformData = (data: any[]) => {
		if (data.length > 0) {
			return data.map(item => {
				const transformedItem: any = { ...item };
				Object.keys(item).forEach(key => {
					if (key !== 'time' && key !== 'argumentation' && key !== 'perspective' && typeof item[key] === 'number') {
						transformedItem[key] = (100 * (item[key] + 1)) / 4;
					}
				});
				return transformedItem;
			});
		} else {
			return data;
		}
	};

	// Extract unique keys from the data
	const keysSet = new Set<string>();
	allData.forEach(item => {
		Object.keys(item).forEach(key => {
			// console.log(key);

			if (key !== 'time' && key !== 'argumentation' && key !== 'perspective') {
				keysSet.add(key);
			}
		});
	});
	const keys = Array.from(keysSet);

	return (
		<div style={{ width: '100%' }}>
			<AreaChart
				width={window.innerWidth - 400}
				height={400}
				data={allData}
				margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="time" />
				<YAxis domain={[0, 100]} allowDataOverflow />
				<Tooltip />
				{keys.map((key, index) => (
					<Area
						key={index}
						type="natural"
						dataKey={key}
						stroke={strokeColor[index % strokeColor.length]}
						fill={fillColor[index % fillColor.length]}
						strokeWidth="4"
						dot={{ stroke: strokeColor[index % strokeColor.length], strokeWidth: 6 }}
						connectNulls
					/>
				))}
			</AreaChart>
			<Box
				sx={{
					marginLeft: '15px',
					marginTop: '15px',
					display: 'flex',
					flexDirection: 'row',
					gap: '30px',
					flexWrap: 'wrap'
				}}
			>
				{keys.map((items, index) => {
					return (
						<>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: '10px'
								}}
							>
								<Box
									sx={{
										background: strokeColor[index],
										width: '15px',
										height: '15px',
										borderRadius: '50%'
									}}
								></Box>
								<Typography sx={{ fontSize: '12px', fontWeight: '500' }}>{items}</Typography>
							</Box>
						</>
					);
				})}
			</Box>
		</div>
	);
};

export default AreaGraph;
