export const timezone = "America/New_York"
export const tempUserId = "3p7uPn1KLfaC7h6ut4GG"

export const defaultFeedbackContent = [
    {
        description: "",
        dimension: "argumentation",
        score: 0,
        name: ""
    },
    {
        description: "",
        dimension: "perspective",
        score: 0,
        name: ""
    }
]
