import { timezone } from "./constants";


/**
 * Convert a given timestamp to a 12-hour formatted time string.
 * @param {number} timestamp - The timestamp to convert.
 * @returns {string} The 12-hour formatted time string.
 */
export const convertTimestampTo12Hour = (timestamp: number) => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || timezone, // Dynamically getting the user's timezone
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
    };
    return date.toLocaleString('en-US', options);
};


/**
 * Get the current timestamp in milliseconds.
 * @returns {number} The current timestamp.
 */
export const getCurrentTimeStamp = () => {
    return Math.floor(Date.now());
}

