import { Box } from '@mui/material';
import React from 'react';
import SimulationHeader from '../../MainSimulation/SimulationHeader';
import SimulationComponent from '../../MainSimulation/SimulationComponent';

const SimulationMain = () => {
	return (
		<Box
			sx={{
				width: '100%',
				px: '32px',
				'@media (max-width: 768px)': {
					px: '10px'
				}
			}}
		>
			<SimulationHeader />
			<SimulationComponent />
		</Box>
	);
};

export default SimulationMain;
