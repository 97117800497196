// * React components
import React, { Suspense, lazy } from 'react';
// * Third party components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Redirect from './Helper/Redirect';
import ProtectedRoute from './Helper/ProtectedRoute';
import DashRoute from './DashRoute';
import { AdminLogin } from './Components/Admin/Login/AdminLogin';
import { PrivatePolicy } from './Components/SitePolicy/PrivatePolicy';
import { TermsOfUse } from './Components/SitePolicy/TermsOfUse';
import UserInstructions from './Components/UserInstructions';

// * Pages;

const MainPage = lazy(() => import('./Components/MainPage/Landing'));
export const AppRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Suspense>
					<Routes>
						<Route path="/" element={<MainPage />} />
						<Route path="/admin" element={<AdminLogin />} />
						<Route path="/redirect/:token" element={<Redirect />} />
						{/* <Route path="/work" element={<InsideNav />} /> */}
						{/* <Route path="/side" element={<ResponsiveDrawer />} /> */}
						<Route
							path="/*"
							element={
								<ProtectedRoute>
									<DashRoute />
								</ProtectedRoute>
							}
						/>
						<Route path="/privacy-policy" element={<PrivatePolicy />} />
						{/* <Route path="/user-instruction" element={<UserInstructions />} /> */}
						<Route path="/terms-of-use" element={<TermsOfUse />} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		</>
	);
};
