

export const getToken = () => {
    const activeToken = localStorage.getItem("active")|| null
    if (activeToken !== null) {
        return JSON.parse(activeToken)
    }
    else return null


}
export const removeToken = () => {
    localStorage.removeItem("active")
    return

}

