import { doc, getDoc, updateDoc, setDoc, DocumentReference, DocumentData } from 'firebase/firestore';
import { v4 as generateUUID } from 'uuid';

import { db } from '../config/firebase';

import { IChat, IConversation, IFeedback, IRecommendation } from '../types';

import { defaultFeedbackContent } from './constants';
import { getCurrentTimeStamp } from './dateFormat';
import { getAuth } from 'firebase/auth';
const auth = getAuth();
/**
 * Add a new conversation to a specific collection.
 * @param {string} collectionName - The name of the collection to add the conversation to.
 * @param {string} userId - The ID of the user for whom the conversation is being added.
 * @param {IChat} initialChat - The initial message of the conversation.
 * @returns {{ id: string, conversations: IConversation[] }} The document with updated list of conversations.
 */
export const addConversation = async (
	collectionName: string,
	userId: string,
	initialChat: IChat,
	scenarioId?: number,
	conversationId?: string
) => {
	const chatDocRef: DocumentReference<DocumentData, DocumentData> = doc(db, collectionName, userId);
	const chatDocSnap = await getDoc(chatDocRef);
	const newConversation: IConversation = {
		// conversationId: conversationId ? conversationId : generateUUID(),
		conversationId: generateUUID(),
		conversationName: initialChat.message
			.split(/[\s,]+/)
			.slice(0, 3)

			.join(' '),

		messages: [initialChat],
		scenarioId: scenarioId || 0,
		time: getCurrentTimeStamp(),
		simulationId: conversationId || ''
	};

	if (chatDocSnap.exists()) {
		const currentData = chatDocSnap.data() as {
			conversations: IConversation[];
		};
		let updatedConversations = [newConversation];
		if (currentData && currentData.conversations) {
			updatedConversations = [newConversation, ...currentData.conversations];
		}
		try {
			await updateDoc(chatDocRef, { conversations: updatedConversations });
		} catch (error) {
			console.log('error while adding conversation', error);
		}
		return {
			id: newConversation?.conversationId,
			conversations: updatedConversations
		};
	} else {
		// Create a new document with an empty conversation
		try {
			await setDoc(chatDocRef, { conversations: [newConversation] });

			return {
				id: newConversation?.conversationId,
				conversations: [newConversation]
			};
		} catch (error) {
			console.error('Error creating new document', error);
		}
	}
};

/**
 * Update an existing conversation in a specific collection.
 * @param {string} collectionName - The name of the collection where the conversation exists.
 * @param {string} userId - The ID of the user who owns the conversation.
 * @param {string} conversationId - The ID of the conversation to update.
 * @param {IChat} newMessage - The new message to add to the conversation.
 * @returns {IConversation[] | undefined} The updated list of conversations.
 */
export const updateConversation = async (
	collectionName: string,
	userId: string,
	conversationId: string,
	newMessage: IChat
) => {
	const chatDocRef: DocumentReference<DocumentData, DocumentData> = doc(db, collectionName, userId);
	const chatDocSnap = await getDoc(chatDocRef);

	if (chatDocSnap.exists()) {
		const currentData = chatDocSnap.data() as {
			conversations: IConversation[];
		};
		if (currentData && currentData.conversations) {
			const updatedConversations = currentData.conversations.map((conversation: IConversation) =>
				conversation.conversationId === conversationId
					? {
							...conversation,
							messages: [...(conversation?.messages || []), newMessage]
						}
					: conversation
			);

			try {
				await updateDoc(chatDocRef, { conversations: updatedConversations });
				return updatedConversations;
			} catch (error) {
				console.log('Error while updating conversation', error);
			}
		} else {
			console.error('No conversations found for user with ID: ', userId);
		}
	} else {
		console.error('Document does not exist');
	}
};

/**
 * Retrieve conversations of a user from a specific collection.
 * @param {string} collectionName - The name of the collection containing user conversations.
 * @param {string} userId - The ID of the user whose conversations to retrieve.
 * @param {string} role - role for simulation
 * @returns {IConversation[]}  The list of containing user conversations.
 */

export const getConversationsOfUser = async (collectionName: string, userId: string) => {
	try {
		const userDocRef = doc(db, collectionName, userId);
		const userDocSnap = await getDoc(userDocRef);
		let conversationsList: IConversation[] = [];
		if (userDocSnap.exists()) {
			conversationsList = userDocSnap.data()?.conversations || [];
		}
		return conversationsList;
	} catch (error) {
		console.error('Error fetching conversation document: ', error);
	}
};

/**
 * Retrieve feedbacks associated with a specific chat message.
 * @param {string} collectionName - The name of the collection containing feedbacks.
 * @param {string} userId - The ID of the user whose feedbacks to retrieve.
 * @param {string} conversationId - The ID of the conversation.
 * @param {string} messageId - The ID of the message.
 * @returns {IFeedback[]} The list of feedbacks associated with the message.
 */
export const getFeedbacksOfChat = async (
	collectionName: string,
	userId: string,
	conversationId: string,
	messageId: string
) => {
	try {
		let feedbacks: IFeedback[] = [];

		const feedbackDocRef = doc(db, collectionName, userId);
		const feedbackDocs = await getDoc(feedbackDocRef);

		const feedbackConversations = feedbackDocs.data()?.conversations || defaultFeedbackContent;

		if (feedbackConversations.length) {
			feedbacks =
				feedbackConversations
					.find((conversation: IConversation) => conversation.conversationId === conversationId)
					?.chats.find((chat: IChat) => {
						return chat.messageId === messageId;
					})?.feedbacks || [];
		}
		return feedbacks;
	} catch (error) {
		console.error('Error fetching user document: ', error);
	}
};

/**
 * Retrieve recommendations associated with a specific chat message.
 * @param {string} collectionName - The name of the collection containing recommendations.
 * @param {string} userId - The ID of the user whose recommendations to retrieve.
 * @param {string} conversationId - The ID of the conversation.
 * @param {string} messageId - The ID of the message.
 * @returns {IRecommendation[]} The list of recommendations associated with the message.
 */
export const getRecommendationsOfChat = async (
	collectionName: string,
	userId: string,
	conversationId: string,
	messageId: string
) => {
	try {
		let recommendations: IRecommendation[] = [];

		const recommendationDocRef = doc(db, collectionName, userId);
		const recommendationDocs = await getDoc(recommendationDocRef);
		const recommendationConversations = recommendationDocs.data()?.conversations || [];

		if (recommendationConversations.length) {
			recommendations =
				recommendationConversations
					.find((conversation: IConversation) => conversation.conversationId === conversationId)
					?.chats.find((chat: IChat) => chat.messageId === messageId)?.recommendations || [];

			return recommendations;
		}
	} catch (error) {
		console.error('Error fetching user document: ', error);
	}
};

export const updateRecommendation = async (
	collectionName: string,
	userId: string,
	conversationId: string,
	messageId: any,
	recommendation: IRecommendation
) => {
	try {
		const recommendationDocRef = doc(db, collectionName, userId);
		const recommendationDocs = await getDoc(recommendationDocRef);
		const recommendationConversations = recommendationDocs.data()?.conversations || [];

		const conversationIndex = recommendationConversations.findIndex(
			(conversation: IConversation) => conversation.conversationId === conversationId
		);

		if (conversationIndex !== -1) {
			const chats = recommendationConversations[conversationIndex].chats;

			const chatIndex = chats.findIndex((chat: IChat) => chat.messageId === messageId);

			if (chatIndex !== -1) {
				const recommendations = chats[chatIndex]?.recommendations || [];

				const recommendationIndex = recommendations.findIndex(
					(rec: IRecommendation) => rec.name === recommendation.name && rec.description === recommendation.description
				);

				if (recommendationIndex !== -1) {
					recommendations[recommendationIndex] = {
						...recommendations[recommendationIndex],
						used: true
					};

					const updatedChats = [...chats];
					updatedChats[chatIndex] = {
						...chats[chatIndex],
						recommendations
					};

					const updatedConversations = [...recommendationConversations];
					updatedConversations[conversationIndex] = {
						...recommendationConversations[conversationIndex],
						chats: updatedChats
					};

					await updateDoc(recommendationDocRef, {
						conversations: updatedConversations
					});

					console.log('Recommendation updated successfully.');
				} else {
					console.log('Recommendation not found.');
				}
			} else {
				console.log('Chat not found.');
			}
		} else {
			console.log('Conversation not found.');
		}
	} catch (error) {
		console.error('Error updating recommendation: ', error);
	}
};

/**
 * Archive a conversation for a user.
 * @param {string} userId - The ID of the user to whom the conversation belongs.
 * @param {string} conversationId - The ID of the conversation to archive.
 * @returns {IConversation[]} The updated list of conversations after archiving.
 */
// export const archiveConversation = async (
//   userId: string,
//   conversationId: string,
//   dbName?:string
// ) => {
//   try {
//     const databaseName = dbName || "chat"
//     const userRef = doc(db, databaseName, userId);
//     const feedbackRef = doc(db,"feedback", userId)
//     const feedbackDocs = await getDoc(feedbackRef);
//     const userDocSnap = await getDoc(userRef);
//     const feedbackConversations =feedbackDocs.data()?.conversations || [];

//     const userConversations = userDocSnap.exists()
//       ? userDocSnap.data()?.conversations || []
//       : [];
//     const conversationIndex = userConversations.findIndex(
//       (conversation: IConversation) =>
//         conversation.conversationId === conversationId
//     );
//     const feedbackIndex = feedbackConversations.findIndex(
//       (conversation: IConversation) =>
//         conversation.conversationId === conversationId
//     );
//     if (conversationIndex !== -1) {
//       userConversations[conversationIndex] = {
//         ...userConversations[conversationIndex],
//         archived: true,
//       };
//       await setDoc(userRef, { conversations: userConversations });
//       return userConversations;
//     }
//    if (feedbackIndex !== -1) {
//     feedbackConversations[feedbackIndex] = {
//       ...feedbackConversations[feedbackIndex], archived: true,
//     }
//     await setDoc(feedbackRef,{ conversations: feedbackConversations });
//    }
//   } catch (error) {
//     console.log("Error while archieving conversation", error);
//   }
//   return [];
// };

export const archiveConversation = async (userId: string, conversationId: string, dbName?: string) => {
	const databaseName = dbName || 'chat';
	try {
		const userRef = doc(db, databaseName, userId);
		const feedbackRef = doc(db, 'feedback', userId);

		// Fetch both documents simultaneously
		const [userDocSnap, feedbackDocs] = await Promise.all([getDoc(userRef), getDoc(feedbackRef)]);

		// Extract or initialize conversations arrays
		const userConversations = userDocSnap.exists() ? userDocSnap.data()?.conversations || [] : [];
		const feedbackConversations = feedbackDocs.data()?.conversations || [];

		// Update archived status for the targeted conversation in both user and feedback conversations
		const updatedUserConversations = userConversations.map((conversation: IConversation) =>
			conversation.conversationId === conversationId
				? { ...conversation, archived: true, deleted_at: getCurrentTimeStamp() }
				: conversation
		);
		const updatedFeedbackConversations = feedbackConversations.map((conversation: IConversation) =>
			conversation.conversationId === conversationId
				? { ...conversation, archived: true, deleted_at: getCurrentTimeStamp() }
				: conversation
		);

		// Save updates to both documents simultaneously
		await Promise.all([
			setDoc(userRef, { conversations: updatedUserConversations }),
			setDoc(feedbackRef, { conversations: updatedFeedbackConversations })
		]);
		return updatedUserConversations;
		// return { userConversations: updatedUserConversations, feedbackConversations: updatedFeedbackConversations };
	} catch (error) {
		console.error('Error while archiving conversation', error);
		throw new Error('Failed to archive conversation');
	}
};
