import React, { useContext, useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ChatContext } from '../../Contexts';
import { useSelector } from 'react-redux';
import getUser from '../../Helper/getUser';
import { getConversationsOfUser, updateRecommendation } from '../../Helper';

const RecommendationBox = ({ from }: { from?: string }) => {
	const [expanded, setExpanded] = useState(false);
	const [innerExpanded, setInnerExpanded] = useState<boolean[]>([]); // Initialize as boolean array

	const user = getUser();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const userId = selectedUserId || user?.id;
	const conversationId = useSelector((state: any) => state.conversationId.conversationId);
	const { recommendations } = useContext(ChatContext);

	useEffect(() => {
		// Reset the expanded state for inner accordions when recommendations change
		setExpanded(false);
		if (recommendations) {
			setInnerExpanded(new Array(recommendations.length).fill(false)); // Set to false for all recommendations
		}
	}, [recommendations]);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	const updateRecommendationHandler = async (recommendation: any) => {
		const conversations = await getConversationsOfUser('simulation', userId);
		const conversation = conversations?.find(conversation => conversation.conversationId === conversationId);
		const lastClientMessage = conversation?.messages.reverse().find(message => message.messageType === 'client');

		await updateRecommendation('recommendation', userId, conversationId, lastClientMessage?.messageId, recommendation);
	};

	const handleInnerToggle = async (index: number, recommendation: any) => {
		setInnerExpanded(prev => {
			const newExpanded = [...prev];
			newExpanded[index] = !newExpanded[index]; // Toggle the specific inner accordion
			return newExpanded;
		});

		// Call the updateRecommendationHandler if the recommendation is not used
		if (!recommendation?.used) {
			await updateRecommendationHandler(recommendation);
		}
	};

	return (
		<div>
			<Accordion sx={{ margin: '0' }} expanded={expanded} onChange={toggleExpand}>
				{from === 'simulation' ? (
					<Tooltip
						title="Click to view recommendations. Your final scores will be reduced if you use this option."
						arrow
					>
						<AccordionSummary
							expandIcon={<IconButton>{expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</IconButton>}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Recommendations</Typography>
						</AccordionSummary>
					</Tooltip>
				) : (
					<AccordionSummary
						expandIcon={<IconButton>{expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</IconButton>}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						<Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Recommendations</Typography>
					</AccordionSummary>
				)}
				<AccordionDetails>
					{recommendations?.length > 0
						? recommendations.map((recommendation: any, key: number) => (
								<Accordion
									sx={{
										marginBottom: '10px',
										border: '1px solid #1C75BA',
										borderRadius: '8px'
									}}
									key={key}
									expanded={innerExpanded[key] ?? false} // Ensure controlled state
									onChange={() => handleInnerToggle(key, recommendation)} // Toggle the inner accordion and call updateRecommendationHandler
								>
									<AccordionSummary
										sx={{ margin: '0', textTransform: 'capitalize' }}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1-content"
										id="panel1-header"
									>
										<Typography color={'#1C75BA'}>{recommendation?.name}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography sx={{ fontSize: '14px' }}>{recommendation?.description}</Typography>
									</AccordionDetails>
								</Accordion>
							))
						: 'There are no recommendations for this meeting.'}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default RecommendationBox;
