import {
	Box,
	Button,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';
import MemberTable, { CategoryType } from '../Member/MemberTable';
import getUser from '../../../Helper/getUser';

const CategoryTable = () => {
	const [tableData, setTableData] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [categoryData, setCategoryData] = useState<CategoryType>({
		scenario_name: '',
		parent_id: null,
		category: '',
		picture: { id: '', name: '', data: '' },
		description: '',
		scenario_id: 0,
		role_name: '',
		video_url: null,
		voice_modal: null,
		badge: { id: '', name: '', data: '' }
	});
	const [parent_id, setParent_id] = useState(0);
	const [showMember, setShowMember] = useState(false);
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();
	useEffect(() => {
		if (isRefresh) {
			getScenarios();
			getFile();
		}
	}, [isRefresh]);
	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					console.log(res.data);

					setTableData(res.data);
					setIsRefresh(false);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const getFile = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allfile`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res?.data?.success) {
					setImageData(res?.data?.data);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};
	const deleteHandler = async (id: number) => {
		await axios
			.delete(`${baseUrl}/api/scenario/deleteScenario/${id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(() => {
				setIsRefresh(!isRefresh);
			})
			.catch(e => {
				console.log(e);
			});
	};
	const editHandler = (data: {
		scenario_name: string;
		parent_id: any;
		category: string;
		picture: { id: string; name: string; data: string };
		description: string;
		scenario_id: number;
		role_name: string;
		video_url?: any;
		voice_modal?: any;
		badge?: { id: string; name: string; data: string };
	}) => {
		setCategoryData({
			scenario_name: data?.scenario_name || '',
			parent_id: data?.parent_id ?? null,
			category: data?.category || '',
			picture: data?.picture || { id: '', name: '', data: '' },
			description: data?.description || '',
			scenario_id: data?.scenario_id || 0,
			role_name: data?.role_name || '',
			video_url: data?.video_url ?? null,
			voice_modal: data?.voice_modal ?? null,
			badge: data?.badge || { id: '', name: '', data: '' }
		});
		setEditOpen(true);
	};
	const memberHandler = (id: number) => {
		setParent_id(id);
		setShowMember(true);
	};
	return (
		<>
			{showMember ? (
				<MemberTable imageData={imageData} parent_id={parent_id} setShowMember={setShowMember} />
			) : (
				<Box sx={{ padding: '0 10px' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
						<Box></Box>
						<Box>
							<Typography variant="h6">Category</Typography>
						</Box>
						<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)} disabled={user?.role === 'editor'}>
							Add
						</Button>
					</Box>
					<Box>
						<TableContainer sx={{ borderRadius: '8px' }}>
							<Table>
								<TableHead
									sx={{
										background: '#CFE9FD',
										border: '1px solid rgba(224, 224, 224, 1)'
									}}>
									<TableRow sx={{ textWrap: 'nowrap' }}>
										<TableCell align="left">Scenario Id</TableCell>
										<TableCell align="left">Scenario Name</TableCell>
										<TableCell align="left">Category</TableCell>
										<TableCell align="left">Description</TableCell>
										<TableCell align="left">Role</TableCell>
										<TableCell align="left">Profile</TableCell>
										<TableCell align="left">Badge</TableCell>
										<TableCell align="left">Edit</TableCell>
										<TableCell align="left">Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(tableData) &&
										tableData.length > 0 &&
										tableData.map((items: CategoryType, index: number) => {
											return (
												<>
													<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)', cursor: 'pointer' }} key={index}>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_id}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_name}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.category}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.description}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.role_name || '-'}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															<img alt="loading" src={items?.picture?.data} style={{ width: '24px', height: '24px' }} />
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.badge ? (
																<img alt="loading" src={items?.badge?.data} style={{ width: '24px', height: '24px' }} />
															) : (
																'Not Assigned'
															)}
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																color="secondary"
																size="small"
																startIcon={<EditIcon />}
																onClick={() => editHandler(items)}>
																Edit
															</Button>
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																size="small"
																startIcon={<DeleteIcon />}
																onClick={() => deleteHandler(items.scenario_id)}>
																Delete
															</Button>
														</TableCell>
													</TableRow>
												</>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}

			<Modal
				open={open}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<CreateCategory setOpen={setOpen} imageData={imageData} setIsRefresh={setIsRefresh} />
			</Modal>

			<Modal
				open={editOpen}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<EditCategory
					setEditOpen={setEditOpen}
					imageData={imageData}
					setIsRefresh={setIsRefresh}
					categoryData={categoryData}
				/>
			</Modal>
		</>
	);
};

export default CategoryTable;
