import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DiscussionForm from './DiscussionForm';
import Discussion from './Discussion';
import DiscussionDetails from './DiscussionDetails';
import getUser from '../../../Helper/getUser';
import PublicDiscussion from './DiscussionStudentView';

export const DiscussionMain = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');
	const [view, setView] = useState<any>('new');
	const user = getUser();

	useEffect(() => {
		if (tab) {
			setView(tab);
		} else {
			setView(null);
		}
	}, [tab]);

	if (view === 'new') {
		return <DiscussionForm />;
	}

	if (view === 'edit') {
		const id = searchParams.get('id');

		return <DiscussionForm id={id} />;
	}

	if (view === 'view') {
		const id = searchParams.get('id');

		return <DiscussionDetails id={id} />;
	}

	if (user?.role === 'admin') {
		return <Discussion />;
	}

	return <PublicDiscussion />;
};
