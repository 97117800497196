import axios from 'axios';
// import { OpenAI } from 'openai';

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
// console.log(apiKey);

// const openai = new OpenAI({
// 	apiKey,
// 	dangerouslyAllowBrowser: true
// });

export const voiceModalOptions = [
	{ name: 'alloy', voice: 'https://cdn.openai.com/API/docs/audio/alloy.wav' },
	{ name: 'echo', voice: 'https://cdn.openai.com/API/docs/audio/echo.wav' },
	{ name: 'fable', voice: 'https://cdn.openai.com/API/docs/audio/fable.wav' },
	{ name: 'onyx', voice: 'https://cdn.openai.com/API/docs/audio/onyx.wav' },
	{ name: 'nova', voice: 'https://cdn.openai.com/API/docs/audio/nova.wav' },
	{ name: 'shimmer', voice: 'https://cdn.openai.com/API/docs/audio/shimmer.wav' }
];

export const textToSpeech = async (
	message: any,
	audioQueue: any,
	setAudioQueue: any,
	voiceModal: 'onyx' | 'alloy' | 'echo' | 'fable' | 'nova' | 'shimmer' = 'onyx'
) => {
	if (message) {
		setAudioQueue([...audioQueue, message]);
		const speechMessage = message;

		try {
			const response = await axios.post('https://api.openai.com/v1/audio/speech', {
				model: 'tts-1',
				voice: voiceModal ?? 'onyx',
				input: speechMessage
			}, {
				headers: {
					'Authorization': `Bearer ${apiKey}`,
					'Content-Type': 'application/json'
				},
				responseType: 'arraybuffer'
			});

			
			const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
			const audioUrl = URL.createObjectURL(audioBlob);

			setAudioQueue((prevAudioQueue: any[]) => {
				const newAudioQueue = [];
				for (const item of prevAudioQueue) {
					if (item === speechMessage) {
						newAudioQueue.push(new Audio(audioUrl));
					} else {
						newAudioQueue.push(item);
					}
				}
				return newAudioQueue;
			});
		} catch (error) {
			console.error('Error creating synthesized speech:', error);
		}
	} else {
		console.log('error');
		return null;
	}
};

export const stopAudio = (audioQueue: any, setAudioQueue: any) => {
	audioQueue.forEach((audio: any) => {
		if (audio instanceof Audio) {
			audio.pause();
		}
	});
	setAudioQueue([]);
	window.speechSynthesis.cancel();
};
