import React from 'react';
import './Simulation.css';
import StepBar from './StepBar/StepBar';

const Simulation = () => {
	// const [title, setTitle] = useState("");
	// const [selectedId, setSelectedId] = useState("");
	// const members = Member();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const handleMember = (member: {
	//   name: any;
	//   memberId?: string;
	//   description?: string;
	// }) => {
	//   setTitle(member?.name || "");
	//   setSelectedId(member?.memberId || "");
	// };
	// const { messages, selectedConversationId } = useContext(ChatContext);
	// const { roleName } = useSelector((state: any) => state?.role);

	return (
		<>
			<StepBar />
		</>
	);
};

export default Simulation;
