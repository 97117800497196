import React, { useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};
interface AddCompanyProps {
	setOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	isRefresh: boolean;
}
type AddCompanyState = {
	name: string;
	contactEmail: string;
	contactFirstName: string;
	contactLastName: string;
};
export const AddCompany: React.FC<AddCompanyProps> = ({ setOpen, setIsRefresh, isRefresh }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [isDisabled, setIsDisabled] = useState(false);
	const [addcompany, setAddcompany] = useState<AddCompanyState>({
		name: '',
		contactEmail: '',
		contactFirstName: '',
		contactLastName: ''
	});
	const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAddcompany({ ...addcompany, [e.target.name]: e.target.value });
	};
	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisabled(true);
		try {
			await axios
				.post(`${baseUrl}/company`, addcompany, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					console.log(res.data);
					setOpen(false);
					setIsRefresh(!isRefresh);
				})
				.catch(e => {
					console.log(e);
					setOpen(false);
				});
		} catch (error) {
			console.log(error);
		} finally {
			setIsDisabled(false);
		}
	};
	return (
		<>
			<Box sx={style}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
					<Typography variant="h6">Create Company</Typography>
					<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setOpen(false)}>
						<HighlightOffOutlinedIcon />
					</Button>
				</Box>
				<Box>
					<div className="formContainer">
						<form onSubmit={submitHandler}>
							<div className="formBox">
								<label htmlFor="companyName">Company Name</label>
								<input
									type="text"
									name="name"
									id="companyName"
									aria-label="name"
									onChange={inputHandler}
									maxLength={120}
									required
									pattern="^[a-zA-Z1-9].*"
									placeholder="Company Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="contactFirstName">Contact First Name</label>
								<input
									type="text"
									name="contactFirstName"
									id="contactFirstName"
									aria-label="contactFirstName"
									onChange={inputHandler}
									maxLength={120}
									pattern="^[a-zA-Z1-9].*"
									required
									placeholder="Contact Person First Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="contactLastName">Contact Last Name</label>
								<input
									type="text"
									name="contactLastName"
									id="contactLastName"
									aria-label="contactLastName"
									onChange={inputHandler}
									maxLength={120}
									pattern="^[a-zA-Z1-9].*"
									required
									placeholder="Contact Person Last Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="companyemail">Company Email</label>
								<input
									type="email"
									name="contactEmail"
									id="companyemail"
									aria-label="companyemail"
									onChange={inputHandler}
									maxLength={120}
									required
									pattern="[-a-zA-Z0-9~!$%^&*_=+{}'?.]+(\.[-a-zA-Z0-9~!$%^&*_=+{}'?.]+)*@[a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*\.([cC][oO][mM])?(:[0-9]{1,5})?"
									placeholder="Contact Person Email"
								/>
							</div>
							<Box>
								<Button disabled={isDisabled} type="submit">
									Submit
								</Button>
							</Box>
						</form>
					</div>
				</Box>
			</Box>
		</>
	);
};
