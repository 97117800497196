import React, { useContext, useEffect, useState } from 'react';
import SimulationBox from './SimulationBox';
import { ChatContext } from '../../../Contexts/ChatContext';
import SimulationInput from './SimulationInput';
import RecommendationBox from '../../Chat/RecommendationBox';
import { getConversationsOfUser } from '../../../Helper';
import getUser from '../../../Helper/getUser';
import { useSelector } from 'react-redux';

const SimulationComponent = () => {
	const { recommendations } = useContext(ChatContext);
	const user = getUser();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const [noOfConversations, setNoOfConversations] = useState(0);

	const getSimulations = async () => {
		let userId;
		selectedUserId ? (userId = selectedUserId) : (userId = user?.id);
		const conversations = await getConversationsOfUser('simulation', userId);
		console.log(conversations, 'conversations');
		if (conversations) {
			setNoOfConversations(conversations?.length);
		}
	};

	useEffect(() => {
		getSimulations();
	}, []);

	return (
		<>
			<div>
				<div className="chatbox">
					<SimulationBox />
					{recommendations && recommendations?.length > 0 && noOfConversations > 1 && (
						<RecommendationBox from={'simulation'} />
					)}
				</div>
				<SimulationInput />
			</div>
		</>
	);
};

export default SimulationComponent;
