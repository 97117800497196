import axios from 'axios'
import { getToken } from './GetToken'

const token = getToken()

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 600000,
  headers: {
    Authorization: token,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    // 'Access-Control-Allow-Headers':
    //   'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  },
})

export default axiosInstance
