const initialState = {
	isAuthenticated: false
};

const authReducer = (state = initialState, action: { type: string; payload: boolean; }) => {
	switch (action.type) {
		case 'SET_AUTH_STATUS':
			return {
				...state,
				isAuthenticated: action.payload
			};
		case 'CLEAR_AUTH_STATUS':
			return {
				...state,
				isAuthenticated: false
			};
		default:
			return state;
	}
};

export default authReducer;