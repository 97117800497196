import React, { useCallback, useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Box, Button, TextField, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import TemplateEditor from './TemplateEditor';
const style = {
	position: 'absolute',
	width: '50%',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	overflowY: 'auto',
	maxHeight: '95vh'
};

const PROMPT_FOR_WEEKLY_EMAIL_SUMMARY = `<p>Hi XXX!</p><p>&nbsp;</p><p>Good job! You’ve completed xxx simulations.</p><p>&nbsp;</p><p>You've done an excellent job in these scenarios xxxx. You did well, such as xxxx.</p><p>&nbsp;</p><p>In the future, try to do xxxx.</p><p>&nbsp;</p><p>[put an open ended questions here to encourage the user to apply the skills in real work or meetings.]</p><p>&nbsp;</p><p>Coming up, you will dive into the following scenarios:</p><p>xxxxx</p><p>&nbsp;</p><p>&nbsp;</p><p>[put new ways to encourage sentence here to cheer the users to put effort in improving communication skills, building better relationships and generate bigger positive impact at work. one example: Keep leveling up your skills and making an even bigger positive impact at work—your efforts are paving the way for excellence! another example: Keep pushing forward to strengthen your connections and amplify your positive impact at work—you’re on the path to making a real difference!]</p><p>&nbsp;</p><p>Best regards,</p><p><br></p><p><strong>Simsola</strong></p>`;

interface EditCohortProps {
	setTemplateOpen: (editOpen: boolean) => void;
	selectedCohort: any;
	editEmailFeatureByCohort: any;
}

const EditCohortTemplate: React.FC<EditCohortProps> = ({
	setTemplateOpen,
	selectedCohort,
	editEmailFeatureByCohort
}) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState(
		selectedCohort?.emailTemplate?.length > 12 ? selectedCohort?.emailTemplate : PROMPT_FOR_WEEKLY_EMAIL_SUMMARY
	);
	const [subject, setSubject] = useState(selectedCohort?.emailSubject || '');

	const inputHandler = (e: { target: { value: string } }) => {
		setSubject(e.target.value);
	};

	useEffect(() => {
		setValue(
			selectedCohort?.emailTemplate?.length > 12 ? selectedCohort?.emailTemplate : PROMPT_FOR_WEEKLY_EMAIL_SUMMARY
		);
	}, [selectedCohort]);

	const handleSubmit = async () => {
		setIsLoading(true);
		await editEmailFeatureByCohort(
			{ ...selectedCohort, emailTemplate: value, emailSubject: subject },
			selectedCohort?.enabledEmailFeature
		);

		setTemplateOpen(false);
		setIsLoading(false);
	};

	const handleSendEmail = async () => {
		setIsLoading(true);
		await editEmailFeatureByCohort(
			{ ...selectedCohort, emailTemplate: value, emailSubject: subject },
			selectedCohort?.enabledEmailFeature
		);

		const token = getToken();
		try {
			await axios.post(
				`${baseUrl}/api/admin/cohort/send-email`,
				{ cohortId: selectedCohort?.id },
				{
					headers: {
						Authorization: token
					}
				}
			);
		} catch (e) {
			console.log(e);
		}

		setTemplateOpen(false);
		setIsLoading(false);
	};

	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Edit Template</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setTemplateOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			<Box>
				<Box sx={{ mb: 2 }}>
					<TextField
						name="Subject"
						placeholder="Enter subject"
						required
						value={subject}
						onChange={inputHandler}
						fullWidth
					/>
				</Box>

				<TemplateEditor style={{ height: 'calc(100vh - 24rem)' }} setValue={setValue} value={value} />
				{/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, mt: 8 }}>
				<Button
					endIcon={<SendIcon />}
					variant="outlined"
					disabled={isLoading || subject?.length == 0}
					onClick={() => {
						handleSendEmail();
					}}
				>
					Send Now
				</Button>
				<Button
					variant="contained"
					disabled={isLoading || subject?.length == 0}
					onClick={() => {
						handleSubmit();
					}}
				>
					Save Template
				</Button>
			</Box>
		</Box>
	);
};

export default EditCohortTemplate;
