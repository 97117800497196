import React, { useState } from 'react';
import { getToken } from '../../../Helper/GetToken';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
	Box,
	Button,
	Chip,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { fireStorage } from '../../../config/firebase';
import DescriptionIcon from '@mui/icons-material/Description';
import { getFileNameFromUrl } from '../../../Helper/encodeURI';
import AudioCard from './AudioCard';
import { voiceModalOptions } from '../../../Helper/TextToSpeech';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};
interface EditCategoryProps {
	setEditOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	categoryData: {
		scenario_name: string;
		parent_id: number | null;
		category: string;
		picture: { id: string; name: string; data: string };
		description: string;
		scenario_id: number;
		role_name: string;
		video_url?: any;
		voice_modal?: any;
		badge?:{ id: string; name: string; data: string };
	};
	imageData: unknown[];
}
const EditCategory: React.FC<EditCategoryProps> = ({ setEditOpen, setIsRefresh, categoryData, imageData }) => {
	const [scenarioData, setScenarioData] = useState({
		scenario_name: categoryData?.scenario_name,
		parent_id: categoryData?.parent_id,
		category: categoryData?.category,
		picture_file_id: categoryData?.picture?.id,
		description: categoryData?.description,
		scenario_id: categoryData?.scenario_id,
		role_name: categoryData?.role_name,
		video_url: categoryData?.video_url,
		voice_modal: categoryData?.voice_modal,
		badge_file_id:categoryData?.badge?.id
	});
	console.log(categoryData);
	
	const [isDisable, setIsDisable] = useState(true);
	const token = getToken();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const formHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
		if (isDisable) {
			setIsDisable(false);
		}
		const { name, value } = e.target;
		setScenarioData(prevState => ({
			...prevState,
			[name as string]: value,
			...(name === 'scenario_name' && { category: value as string }) // Update category if scenario_name is updated
		}));
	};

	const [video, setVideo] = useState<File | string | null>(categoryData?.video_url || null);
	const [isVideo, setIsVideo] = useState<boolean>(categoryData?.video_url?.includes('scenario_videos') || false);
	const [loadingForVideo, setLoadingForVideo] = useState(false);
	const [selectedVoiceModal, setSelectedVoiceModal] = useState<any>(categoryData?.voice_modal);
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (file) {
			setVideo(file);
			const fileType = file?.type;
			const isVideoFile = fileType?.includes('video');
			const isTextFile = fileType?.includes('text');

			if (isVideoFile) {
				setIsVideo(true);
			} else if (isTextFile) {
				setIsVideo(false);
			}
			setIsDisable(false);
		}
	};

	const extractFilePath = (videoURL: string): string => {
		const regex = /https:\/\/firebasestorage.googleapis.com\/v0\/b\/[^/]+\/o\/([^?]+)\?alt=media/;
		const match = videoURL?.match(regex);
		if (match && match[1]) {
			return decodeURIComponent(match[1]);
		}
		throw new Error('Invalid URL');
	};

	const handleVideoDelete = async () => {
		setIsDisable(false);
		setVideo(null);
	};

	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisable(true);

		let videoUrl = video;

		if (video && typeof video !== 'string') {
			setLoadingForVideo(true);
			const fileName = `${new Date().getTime()}-${video?.name}`;
			const storageRef = await ref(fireStorage, isVideo ? `scenario_videos/${fileName}` : `scenario_texts/${fileName}`);

			const snapshot = await uploadBytes(storageRef, video);
			const downloadURL = await getDownloadURL(snapshot.ref);
			console.log('Uploaded file URL:', downloadURL);
			videoUrl = downloadURL;
			setLoadingForVideo(false);
		}

		if (
			categoryData?.video_url &&
			categoryData?.video_url !== video &&
			categoryData?.video_url?.includes('firebasestorage.googleapis.com')
		) {
			setLoadingForVideo(true);
			try {
				const filePath = extractFilePath(categoryData?.video_url);
				const storageRef = ref(fireStorage, filePath);
				await deleteObject(storageRef);

				setLoadingForVideo(false);
			} catch (error) {
				setLoadingForVideo(false);
				console.error('Error deleting file:', error);
			}
		}

		await axios
			.put(
				`${baseUrl}/api/scenario/editScenario`,
				{ ...scenarioData, video_url: videoUrl, voice_modal: selectedVoiceModal },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(res => {
				if (res?.data?.success) {
					setScenarioData({
						scenario_name: '',
						parent_id: null,
						category: '',
						picture_file_id: '',
						description: '',
						scenario_id: 0,
						role_name: '',
						video_url: null,
						voice_modal: null,
						badge_file_id:""
					});
					setEditOpen(false);
					setIsRefresh(true);
					setIsDisable(!isDisable);
				}
			})
			.catch(e => {
				console.log(e);
				setIsDisable(!isDisable);
			});
	};

	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Add</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setEditOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			<form onSubmit={submitHandler}>
				<FormControl fullWidth sx={{ gap: 2 }}>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Scenario Name"
							name="scenario_name"
							type="text"
							value={scenarioData.scenario_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Description"
							name="description"
							type="text"
							value={scenarioData.description}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Role"
							name="role_name"
							type="text"
							value={scenarioData.role_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						{/* <InputLabel id="profile-label">Profile</InputLabel> */}
						<Select
							// labelId="profile-label"
							id="outlined-required"
							label="label"
							name="picture_file_id"
							value={scenarioData.picture_file_id}
							onChange={formHandler}
							fullWidth
							required>
							{Array.isArray(imageData) &&
								imageData.filter((item: any) => item.usage !== 'badge')
								.map((item: any, index: number) => (
									<MenuItem key={index} value={item.id}>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<img alt={item.name} src={item.data} style={{ width: '20px', height: '20px' }} />
											<Typography>{item.name}</Typography>
										</Box>
									</MenuItem>
								))}
						</Select>
					</Box>
					{categoryData?.parent_id ? (
						''
					) : (
						<Box>
							<Typography>Badge</Typography>
							<Select
								name="badge_file_id"
								value={scenarioData?.badge_file_id || undefined}
								onChange={formHandler}
								inputProps={{ 'aria-label': 'Without label' }}
								fullWidth
								required>
								{Array.isArray(imageData) &&
									imageData.filter((item: any) => item.usage === 'badge') // Filter for items with usage equal to 'badge'
									.map((item: any, index: number) => (
										<MenuItem key={index} value={item.id}>
											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
												<img alt={item.name} src={item.data} style={{ width: '20px', height: '20px' }} />
												<Typography>{item.name}</Typography>
											</Box>
										</MenuItem>
									))}
							</Select>
						</Box>
					)}
					<Box>
						<label>Voice Modals</label>
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '100%', marginTop: '4px' }}>
							{Array.isArray(imageData) &&
								voiceModalOptions.map((item: any, index: number) => (
									<AudioCard
										item={item}
										key={index}
										setSelectedVoiceModal={e => {
											setIsDisable(false);
											setSelectedVoiceModal(e);
										}}
										selectedVoiceModal={selectedVoiceModal}
									/>
								))}
						</div>
					</Box>
					<Box>
						{loadingForVideo ? (
							<LinearProgress />
						) : video ? (
							<Box display={'flex'} width={'100%'} gap={1}>
								{isVideo ? (
									<video
										controls
										style={{ maxWidth: '300px' }}
										src={typeof video === 'string' ? video : URL.createObjectURL(video)}>
										Your browser does not support to load the video.
									</video>
								) : (
									<div
										onClick={() => {
											if (typeof video === 'string') {
												window.open(video, '_blank');
											}
										}}
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 1,
											width: '100%',
											cursor: typeof video === 'string' ? 'pointer' : 'auto'
										}}>
										<DescriptionIcon color="primary" fontSize="large" />
										<p>{typeof video !== 'string' ? video?.name && video?.name : getFileNameFromUrl(video)}</p>
									</div>
								)}
								<CancelIcon onClick={handleVideoDelete} sx={{ color: 'red', cursor: 'pointer' }} />
							</Box>
						) : (
							<input accept=".mp4,.webm,.ogg,.txt" id="contained-button-file" type="file" onChange={handleFileChange} />
						)}
					</Box>
					<Box>
						<Button type="submit" disabled={isDisable}>
							Submit
						</Button>
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default EditCategory;
