import { useCallback, useContext, useEffect } from "react";
import { ChannelE, SSEContext } from "../Contexts";

export function useChannel(
  channelName: ChannelE,
  callback?: (event: any) => void
) {
  const { state, postMessage } = useContext(SSEContext);
  useEffect(() => {
    if(callback && state[channelName])
      callback(state[channelName]);

  }, [state[channelName]]);

  const sendMessage = useCallback((message: any) => {
    return postMessage(channelName, message)
  }, [postMessage])
  return {[channelName+'State']:state[channelName], sendMessage}
}
