import { Box } from '@mui/material';
import React from 'react';
import DebriefHeader from '../../SimulationDebrief/DebriefHeader';

const SimulationDebrief = ({ setActiveStep }: any) => {
	return (
		<>
			<Box
				sx={{
					width: '100%',
					px: '32px',
					'@media (max-width: 768px)': {
						px: '10px'
					}
				}}
			>
				<DebriefHeader setActiveStep={setActiveStep} />
			</Box>
			{/* <DebriefBox  /> */}
		</>
	);
};

export default SimulationDebrief;
