import React from 'react';

import axios from 'axios';
import { getToken, removeToken } from '../../Helper/GetToken';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	clearConversationId,
	clearSelectedUser,
	clearSimulationStep,
	clearUserDetails,
	setRole,
	clearSelectedCompletedSimulationMessages,
	setRoleCategory,
	clearAuthStatus
} from '../../Helper/Redux/Action/userAction';
const Logout = () => {
	const token = getToken();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// eslint-disable-next-line no-undef
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const logoutHandler = async () => {
		await axios
			.get(`${baseUrl}/logout`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					removeToken();
					dispatch(clearUserDetails());
					dispatch(clearSelectedUser());
					dispatch(clearConversationId());
					dispatch(clearSimulationStep());
					dispatch(setRole(null));
					dispatch(setRoleCategory(null));
					dispatch(clearSelectedCompletedSimulationMessages());
					dispatch(clearAuthStatus());
					navigate('/');
				}
			})
			.catch(err => {
				console.log(err);
				removeToken();
				dispatch(clearUserDetails());
				dispatch(clearAuthStatus());
				dispatch(clearSimulationStep());
				navigate('/');
			});
	};
	return (
		<div>
			<button className="logutBtn" onClick={logoutHandler}>
				Logout
			</button>
		</div>
	);
};

export default Logout;
