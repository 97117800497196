import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
	AdminRouteConfig,
	clientRouteConfig,
	coachRouteConfig,
	editorRouteConfig
} from './Components/BreadCrum/routeConfig';
import { InsideNav } from './Components/TopNav/InsideNav/InsideNav';
import { Box } from '@mui/material';
import getUser from './Helper/getUser';

const SideDrawer = lazy(() => import('./Components/SideDrawer/sideDrawer'));
type UserRole = 'client' | 'admin' | 'editor' | 'coach';
const DashRoute = () => {
	const [runFunc, setRunFunc] = useState(true);
	const user = getUser();
	const routeConfigs: Record<
		UserRole,
		{ path: string; name: string; component: JSX.Element; layout: string; icon: string; sideDrawer: boolean }[]
	> = {
		client: clientRouteConfig,
		admin: AdminRouteConfig,
		editor: editorRouteConfig,
		coach: coachRouteConfig
	};

	const routes = routeConfigs[user?.role as UserRole] || [];
	const drawerWidth = 350; // Define the width of the SideDrawer

	const handleDrawerToggle = () => {
		setRunFunc(!runFunc);
	};
	useEffect(() => {
		if (window && window.innerWidth <= 900) {
			// md breakpoint
			setRunFunc(false); // Always show drawer in desktop view
		}
	}, [window]);
	return (
		<>
			<InsideNav handleDrawerToggle={handleDrawerToggle} />
			<Box sx={{ display: 'flex', width: '100%' }}>
				<SideDrawer runFunc={runFunc} drawerWidth={drawerWidth} setRunFunc={setRunFunc} />
				<Box
					sx={{
						width: `${runFunc ? `calc(100% - ${drawerWidth}px)` : '100%'}`,
						ml: { xs: runFunc ? `${drawerWidth}px` : 0, md: 0 },
						transition: 'width 0.3s, margin-left 0.3s'
					}}>
					<Routes>
						{routes.map((items, index) => (
							<Route key={index} path={items.path} element={items.component} />
						))}
					</Routes>
				</Box>
			</Box>
		</>
	);
};

export default DashRoute;
