import React from 'react';
import { Typography, Box, Link } from '@mui/material';

export const PrivatePolicy = () => {
	return (
		<>
			<section>
				<div className="container">
					{' '}
					<Box sx={{ width: '100%', my: '20px' }}>
						<Typography variant="h3" component="h1" gutterBottom style={{fontFamily:"Raleway",fontWeight:"500"}}>
							Privacy Policy
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							Welcome to Simsola.ai! This privacy policy describes how we handle your personal information. By using
							Simsola.ai (the &quot;Site&quot;), you consent to the collection, use, and disclosure of your information
							as described in this policy.
						</Typography>

						<Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
							Information Collection
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							We collect information that you provide directly to us. This includes when you create an account, fill out
							a form, or communicate with us. The types of information we may collect include your name, email address,
							phone number, and any other information you choose to provide.
						</Typography>

						<Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}> 
							Use of Information
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							We may use the information we collect to provide, maintain, and improve our services, to communicate with
							you, to monitor and analyze trends, usage, and activities, and for other purposes with your consent.
						</Typography>

						<Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
							Sharing of Information
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							We may share information about you as follows or as otherwise described in this privacy policy:
							<ul>
								<li>
									With vendors and service providers who need access to such information to carry out work on our behalf
								</li>
								<li>
									In response to a request for information if we believe disclosure is in accordance with, or required
									by, any applicable law, regulation, or legal process
								</li>
								<li>
									If we believe your actions are inconsistent with our user agreements or policies, or to protect the
									rights, property, and safety of Simsola.ai or others
								</li>
								<li>With your consent or at your direction</li>
							</ul>
						</Typography>

						<Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
							Security
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							We take reasonable measures to help protect information about you from loss, theft, misuse, and
							unauthorized access, disclosure, alteration, and destruction.
						</Typography>

						<Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
							Contact Us
						</Typography>
						<Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
							If you have any questions about this privacy policy, please contact us at{' '}
							<Link href="mailto:info@simsola.ai">info@simsola.ai</Link>.
						</Typography>
					</Box>
				</div>
			</section>
		</>
	);
};
