import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	CircularProgress,
	Snackbar,
	Alert,
	styled,
	Checkbox,
	ListItemText
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../../Helper/GetToken';
import { IPostgresUser } from '../../../types/user';
import { ICohort } from '../../../types/cohort';
import { useDispatch } from 'react-redux';
import { setSelectedUser } from '../../../Helper/Redux/Action/userAction';
import { Link } from 'react-router-dom';

// const roles = [
// 	{ value: 1, name: 'Client' },
// 	{ value: 2, name: 'Coach' }
// ];
interface Role {
	id: string; // or `number` depending on your data
	name: string;
}
const StyledLink = styled(Link)({
	textDecoration: 'none',
	color: 'inherit'
});

const Users = () => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [users, setUsers] = useState<IPostgresUser[]>([]);
	const [cohorts, setCohorts] = useState<ICohort[]>([]);
	const [role, setRole] = useState('');
	const [cohort, setCohort] = useState('');
	const [email, setEmail] = useState('');
	const [roles, setRoles] = useState([]);
	const [organization, setOrganization] = useState('');
	const [errors, setErrors] = useState({ email: '', role: '', cohort: '' });
	const token = getToken();
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'info' | 'warning' | undefined;
		message?: string;
	}>();
	const [search, setSearch] = useState('');
	const [selectedCohorts, setSelectedCohorts] = useState<number[]>([]);
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const handleRoleChange = (event: SelectChangeEvent) => {
		setRole(event.target.value as string);
	};

	const handleCohortChange = (event: SelectChangeEvent) => {
		setCohort(event.target.value as string);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOrganization(event.target.value);
	};

	const dispatch = useDispatch();

	const validateInputs = () => {
		const newErrors = { email: '', role: '', cohort: '' };
		let isValid = true;

		if (!email) {
			newErrors.email = 'Email is required';
			isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
			isValid = false;
		}

		if (!role) {
			newErrors.role = 'Role is required';
			isValid = false;
		}

		if (!cohort) {
			newErrors.cohort = 'Cohort is required';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async () => {
		if (validateInputs()) {
			const token = getToken();
			try {
				await axios.post(
					`${baseUrl}/api/admin/inviteUser`,
					{
						userEmail: email,
						roleId: role,
						cohortId: cohort,
						organizationId: organization
					},
					{
						headers: {
							Authorization: token
						}
					}
				);
				setEmail('');
				setRole('');
				setCohort('');
				setOrganization('');
				setOpenAlert({
					open: true,
					type: 'success',
					message: 'User invited successfully!'
				});
			} catch (e: any) {
				console.log(e);
				setOpenAlert({
					open: true,
					type: 'error',
					message: e?.response?.data?.message || 'Something went wrong!'
				});
			}
		}
	};

	const getAllCohorts = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/api/admin/getAllCohorts`, {
				headers: {
					Authorization: token
				}
			});
			setCohorts(response.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getAllUsers = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/getAllUsers`, {
				headers: {
					Authorization: token
				}
			});
			setUsers(response.data);
		} catch (e) {
			console.log(e);
		}
	};

	const changeUserStatus = async (userId: string, curretStatus: string) => {
		const status = curretStatus === 'active' ? 'inactive' : 'active';
		try {
			const response = await axios.patch(
				`${baseUrl}/api/admin/updateUserAccountStatus/${userId}`,
				{
					status: status
				},
				{
					headers: {
						Authorization: getToken()
					}
				}
			);
			if (response.status === 200) {
				getAllUsers();
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getAllUsers();
		getAllCohorts();
		getRoles();
	}, []);
	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
	};
	const getRoles = async () => {
		await axios
			.get(`${baseUrl}/api/admin/getall/role`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setRoles(res.data.roles);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};
	const handleChange = (event: SelectChangeEvent<number[]>) => {
		const { value } = event.target;
		setSelectedCohorts(typeof value === 'string' ? value.split(',').map(Number) : (value as number[]));
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			<Box
				sx={{
					width: '100%',
					padding: '24px',
					display: 'flex',
					flexDirection: 'column',
					gap: '32px',
					fontFamily: 'IBM Plex Sans'
				}}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>Users</Typography>

					<Box>
						<FormControl sx={{ mr: 2, width: '300px' }}>
							<InputLabel id="cohort-select-label">Select Cohorts</InputLabel>
							<Select
								labelId="cohort-select-label"
								id="cohort-select"
								multiple
								label="Select Cohorts"
								sx={{ width: '300px' }} // Ensure a fixed width for the Select component
								value={selectedCohorts}
								onChange={handleChange}
								renderValue={selected =>
									selected.map(id => cohorts?.find(c => c.id === id)?.name || 'Include N/A').join(', ')
								}
								MenuProps={{
									PaperProps: {
										style: {
											width: '300px' // Ensure the dropdown matches the Select component width
										}
									},
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left' // Anchor dropdown relative to Select component
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left' // Ensure dropdown doesn't shift left
									}
								}}>
								{cohorts.map(cohort => (
									<MenuItem key={cohort.id} value={cohort.id} sx={{ width: '300px' }}>
										{' '}
										{/* Fixed width for menu items */}
										<Checkbox checked={selectedCohorts.indexOf(cohort.id) > -1} />
										<ListItemText primary={cohort.name} />
									</MenuItem>
								))}
								<MenuItem value={0} sx={{ width: '300px' }}>
									{' '}
									{/* Fixed width for N/A option */}
									<Checkbox checked={selectedCohorts.indexOf(0) > -1} />
									<ListItemText primary={`Include N/A`} />
								</MenuItem>
							</Select>
						</FormControl>

						<TextField
							name="search"
							placeholder="Type here to search"
							required
							value={search}
							onChange={inputHandler}
							fullWidth
							sx={{ width: '180px' }}
						/>
					</Box>
				</Box>
				<TableContainer sx={{ borderRadius: '8px' }}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead
							sx={{
								background: '#CFE9FD',
								border: '1px solid rgba(224, 224, 224, 1)'
							}}>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell align="left">First name</TableCell>
								<TableCell align="left">Last name</TableCell>
								<TableCell align="left">Role</TableCell>
								<TableCell align="left">Cohort</TableCell>
								<TableCell align="left">Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users
								?.filter(user => {
									// If no cohorts are selected, return all users
									if (selectedCohorts.length === 0) {
										return true;
									}

									// If "N/A" (cohorts are null or empty) is selected, include users with empty or null cohort arrays
									const isEmptyCohortSelected = selectedCohorts.includes(0);
									const hasEmptyCohort = !Array.isArray(user.cohort) || user.cohort.length === 0;

									if (isEmptyCohortSelected && hasEmptyCohort) {
										return true;
									}

									// Otherwise, filter by selected cohorts
									return Array.isArray(user.cohort) && user.cohort.some(cohort => selectedCohorts.includes(cohort.id));
								})
								?.filter(
									items =>
										items.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
										items?.last_name?.toLowerCase()?.includes(search?.toLowerCase())
								)
								.map((user: IPostgresUser) => {
									return (
										<TableRow key={user.id} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
											<TableCell component="th" scope="row">
												{user.role.name === 'client' ? (
													<StyledLink
														to={'/progress'}
														onClick={() => user?.firebase_id !== null && dispatch(setSelectedUser(user.firebase_id))}>
														{user.email}
													</StyledLink>
												) : (
													user.email
												)}
											</TableCell>
											<TableCell align="left">
												{user.role.name === 'client' ? (
													<StyledLink
														to={'/progress'}
														onClick={() => user?.firebase_id !== null && dispatch(setSelectedUser(user.firebase_id))}>
														{user.first_name}
													</StyledLink>
												) : (
													user.first_name
												)}
											</TableCell>
											<TableCell align="left">
												{user.role.name === 'client' ? (
													<StyledLink
														to={'/progress'}
														onClick={() => user?.firebase_id !== null && dispatch(setSelectedUser(user.firebase_id))}>
														{user.last_name}
													</StyledLink>
												) : (
													user.last_name
												)}
											</TableCell>
											<TableCell align="left">{user.role.name}</TableCell>
											<TableCell align="left">
												{Array.isArray(user.cohort) && user.cohort.length > 0
													? user.cohort.map(items => items.name).join(', ')
													: 'N/A'}
											</TableCell>
											<TableCell
												align="left"
												sx={{ color: user.account_status === 'active' ? 'green' : 'red', gap: '5px' }}>
												<Typography>{user.account_status}</Typography>
												<Button
													variant="contained"
													onClick={() => changeUserStatus(user.firebase_id, user.account_status)}>
													{user.account_status === 'active' ? 'Deactivate' : 'Activate'}
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>Invitation</Typography>
				<TextField
					id="outlined-basic"
					label="User mail"
					variant="outlined"
					value={email}
					onChange={handleEmailChange}
					error={!!errors.email}
					helperText={errors.email}
				/>
				<FormControl fullWidth error={!!errors.role}>
					<InputLabel id="user-role-label">Role</InputLabel>
					<Select labelId="user-role-label" id="user-role" value={role} label="Role" onChange={handleRoleChange}>
						{roles
							?.filter((item: { name: string }) => item.name?.toLowerCase() !== 'admin')
							.map((role: Role) => (
								<MenuItem key={role?.id} value={role?.id}>
									{role?.name}
								</MenuItem>
							))}
					</Select>
					{errors.role && <Typography color="error">{errors.role}</Typography>}
				</FormControl>
				<FormControl fullWidth error={!!errors.cohort}>
					<InputLabel id="user-cohort-label">Cohort</InputLabel>
					<Select
						labelId="user-cohort-label"
						id="user-cohort"
						value={cohort}
						label="Cohort"
						onChange={handleCohortChange}>
						{cohorts.map(cohort => (
							<MenuItem key={cohort.id} value={cohort.id}>
								{cohort.name}
							</MenuItem>
						))}
					</Select>
					{errors.cohort && <Typography color="error">{errors.cohort}</Typography>}
				</FormControl>
				<TextField
					id="outlined-basic"
					label="Organization (optional)"
					variant="outlined"
					value={organization}
					onChange={handleOrganizationChange}
				/>
				<Button variant="contained" onClick={handleSubmit}>
					Send
				</Button>
			</Box>
		</>
	);
};

export default Users;
