import React from 'react';
import ActivityByCategory from './ActivityByCategory';
import UsersSummary from './UsersSummary';

const UserActivity = () => {
	return (
		<>
			<UsersSummary />
			<ActivityByCategory />
		</>
	);
};

export default UserActivity;
