import { combineReducers } from 'redux';
import {
	conversationIdReducer,
	roleReducer,
	userReducer,
	voiceReducer,
	simulationReducer,
	simulationStepReducer,
	initialSelectedUserReducer,
	selectedCompletedSimulationReducer,
	roleCategoryReducer,
	lastConversationReducer,
	levelReducer,
	cohortSimulationReducer,
	badgeSimulationReducer,
	cohortReducer,
} from './userReducer';
import authReducer from './authReducer';
const rootReducer: any = combineReducers({
	// other reducers
	user: userReducer,
	voice: voiceReducer,
	role: roleReducer,
	conversationId: conversationIdReducer,
	simulation: simulationReducer,
	simulationStep: simulationStepReducer,
	selectedUser: initialSelectedUserReducer,
	selectedCompletedSimulation: selectedCompletedSimulationReducer,
	roleCategory: roleCategoryReducer,
	auth: authReducer,
	lastCoversationMessage: lastConversationReducer,
	level: levelReducer,
	cohortSimulation : cohortSimulationReducer,
	badgeSimulation : badgeSimulationReducer,
	selectedCohort: cohortReducer
});

export default rootReducer;
