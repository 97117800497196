import React from 'react';
import './Notification.css';

interface INotificationProps {
	text: string;
}

const Notification: React.FC<INotificationProps> = ({ text }) => {
	return (
		<div className="token-limit-message">
			<p>{text}</p>
		</div>
	);
};

export default Notification;
