import React from 'react';
import { Box, Typography, Button, Popover, Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import scenarioIcon from '../../../Assets/Images/discussion-item.svg';
import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
import { useNavigate } from 'react-router-dom';
import PushPinIcon from '@mui/icons-material/PushPin';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import getUser from '../../../Helper/getUser';

import ReplyIcon from '@mui/icons-material/Reply';

const stylesForNumbers = {
	container: {
		display: 'flex',
		border: '2px solid #0078D4', // blue border
		borderRadius: '50px', // rounded edges
		overflow: 'hidden',
		width: '50px',
		fontFamily: 'Arial, sans-serif',
		height: '24px',
		marginRight: '20px'
	},
	leftSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#0078D4', // blue background
		color: '#fff',
		width: '50%'
	},
	rightSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#f0f0f0', // grey background
		color: '#333',
		width: '50%'
	},
	number: {
		fontSize: '12px',
		fontWeight: 'bold'
	}
};

interface DiscussionListProps {
	discussion: any;
	type: string;
	getDiscussions: () => void;
}

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

const DiscussionList: React.FC<DiscussionListProps> = ({ discussion, type, getDiscussions }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const navigate = useNavigate();
	const user = getUser();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handlePinUnpin = async () => {
		await axios
			.post(
				`${baseUrl}/api/admin/update-discussion/${discussion?.id}`,
				{ isPinned: !discussion?.isPinned },
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)
			.then(res => {
				if (res.data.success) {
					setAnchorEl(null);
					getDiscussions();
				}
			});
	};
	const handleActiveClosed = async () => {
		await axios
			.post(
				`${baseUrl}/api/admin/update-discussion/${discussion?.id}`,
				{ isActive: !discussion?.isActive },
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)
			.then(res => {
				if (res.data.success) {
					setAnchorEl(null);
					getDiscussions();
				}
			});
	};

	const handleDelete = async () => {
		await axios
			.delete(`${baseUrl}/api/admin/delete-discussion/${discussion?.id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				if (res.data.success) {
					setAnchorEl(null);
					getDiscussions();
				}
			});
	};

	const handleEdit = () => {
		navigate(`/discussions?tab=edit&id=${discussion?.id}`);
	};
	const handleViewDiscussion = () => {
		navigate(`/discussions?tab=view&id=${discussion?.id}`);
	};

	return (
		<Box
			sx={{
				borderBottom: '1px solid #ddd',
				display: 'flex',
				padding: '8px 0',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
				<Box>
					<img src={scenarioIcon} alt="" />
				</Box>
				<Box sx={{ cursor: 'pointer' }} onClick={handleViewDiscussion}>
					<Typography variant="body1">{discussion?.title}</Typography>
					<Typography style={{ fontSize: '12px' }} variant="body2" color="textSecondary">
						Opened on {moment(discussion?.created_at).format('MMM DD, hh:mm A')}
						<p>
							By{' '}
							<span style={{ fontWeight: '500' }}>
								{discussion?.openedBy?.firebase_id === user?.id
									? 'You'
									: `${discussion?.openedBy?.first_name} ${discussion?.openedBy?.last_name}`}
							</span>
						</p>
					</Typography>
				</Box>
			</div>
			<Box textAlign="right">
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					<Box sx={{ p: 2, pb: 0 }}>
						{type !== 'draft' && user?.role === 'admin' && (
							<Typography style={{ cursor: 'pointer' }} sx={{ pb: 2 }} onClick={handleActiveClosed}>
								{discussion?.isActive ? <LockIcon sx={{ mr: 1 }} /> : <LockOpenIcon sx={{ mr: 1 }} />}
								{discussion?.isActive ? 'Closed' : 'Open'}
							</Typography>
						)}
						{type !== 'draft' && user?.role === 'admin' && (
							<Typography style={{ cursor: 'pointer' }} sx={{ pb: 2 }} onClick={handlePinUnpin}>
								<PushPinIcon sx={{ mr: 1 }} />
								{discussion?.isPinned ? 'Unpin' : 'Pin'}
							</Typography>
						)}
						<Typography style={{ cursor: 'pointer' }} sx={{ pb: 2 }} onClick={handleEdit}>
							<BorderColorIcon sx={{ mr: 1 }} />
							Edit
						</Typography>
						{user?.role === 'admin' && (
							<Typography style={{ cursor: 'pointer' }} sx={{ pb: 2 }} onClick={handleDelete}>
								<DeleteIcon sx={{ mr: 1 }} />
								Delete
							</Typography>
						)}
					</Box>
				</Popover>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: '8px' }}>
					{type === 'pinned' &&
						(discussion?.isActive ? '' : <Chip size="small" variant="outlined" color="error" label="Closed" />)}
					{!discussion?.isPublished && <Chip size="small" color="error" label="In Draft" />}
					{discussion?.replies?.length > 0 && (
						<div style={stylesForNumbers.container}>
							<div style={stylesForNumbers.leftSection}>
								<ReplyIcon fontSize="small" />
							</div>
							<div style={stylesForNumbers.rightSection}>
								<span style={stylesForNumbers.number}>{discussion?.replies?.length}</span>
							</div>
						</div>
					)}
					{(user?.role === 'admin' || discussion?.openedBy?.firebase_id === user?.id) && (
						<Button
							style={{ padding: '0px', height: '0px', textAlign: 'right', paddingTop: '5px' }}
							aria-describedby={id}
							onClick={handleClick}
						>
							<MoreVertIcon
								style={{
									color: '#656565',
									marginBottom: '8px',
									cursor: 'pointer',
									background: '#E9E9E9',
									border: '1px solid #D4D4D4',
									borderRadius: '4px'
								}}
							/>
						</Button>
					)}
				</div>
				<Typography variant="body2" sx={{ marginTop: '8px', marginRight: '20px' }} color="textSecondary">
					Due {moment(discussion?.until).format('MMM DD, hh:mm A')}
				</Typography>
			</Box>
		</Box>
	);
};

export default DiscussionList;
