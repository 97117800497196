import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import mike from '../Assets/Images/keyboard_voice.svg';
import coachResp from '../Assets/Images/coach_responce.svg';
import PropTypes from 'prop-types';
import { getToken } from './GetToken';
import { ChatContext } from '../Contexts';
import { useDispatch } from 'react-redux';
import { setEnableVoice } from './Redux/Action/userAction';
import { CircularProgress } from '@mui/material';

interface AudioRecorderProps {
	valueHandler: (data: string) => void;
	onTimerUpdate: (time: any) => void;
	updateAudioRecorder: (status: boolean) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ valueHandler, onTimerUpdate, updateAudioRecorder }) => {
	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [duration, setDuration] = useState<number>(5 * 60); // Initialize duration to 5 minutes in seconds
	const mediaRecorderRef = useRef<MediaRecorder | any>(null);
	const audioChunksRef = useRef<Blob[]>([]);
	const audioThresholdRef = useRef<number>(0);
	const underOneStartTimestampRef = useRef<number | any>(null);
	const token = getToken();
	const startTimeRef = useRef<number | null>(null);
	const [previousCoachSays, setPreviousCoachSays] = useState<boolean | null>(null);
	const [startTime, setStartTime] = useState<Date | null>(null);
	const dispatch = useDispatch();

	const [newFormattedTime, setNewFormattedTime] = useState<string>('00:00');

	const [volume, setVolume] = useState<number>(0); // Variable to store the volume level
	const [isSilenceDetected, setIsSilenceDetected] = useState<boolean>();
	const streamRef = useRef<MediaStream | null>(null);
	const intermediateRecorderRef = useRef<MediaRecorder | null>(null);
	const intermediateChunksRef = useRef<Blob[]>([]);
	const [isDidSpeak, setIsDidSpeak] = useState<boolean>(false);
	const { chatLoading, coachSays, micVisual, setMicVisual, setChatLoading } = useContext(ChatContext);

	// eslint-disable-next-line no-undef
	const baseUrl: string = process.env.REACT_APP_BACKEND_BASE_URL || '';

	const handleMediaRecorderData = (event: BlobEvent) => {
		audioChunksRef.current.push(event.data);
	};

	const handleIntermediateRecorderData = (event: BlobEvent) => {
		intermediateChunksRef.current.push(event.data);
	};

	const handleStopIntermediateRecorder = () => {
		const audioBlob = new Blob(intermediateChunksRef.current, {
			type: 'audio/wav'
		});
		intermediateChunksRef.current = [];
		sendAudioToServer(audioBlob);
	};

	useEffect(() => {
		if (isSilenceDetected && isRecording) {
			// stopIntRec();
			stopRecording();
		} else {
			if (!chatLoading && !coachSays) {
				newIntRec();
			}
		}
	}, [isSilenceDetected]);

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (isRecording && duration > 0) {
			interval = setInterval(() => {
				setDuration(prevDuration => {
					if (!startTime) return prevDuration;
					const now = new Date();
					const elapsedTime = now.getTime() - startTime.getTime();
					const newDuration = prevDuration - 1;
					// Convert the duration to mm:ss format
					const minutes = Math.floor(newDuration / 60);
					const seconds = newDuration % 60;
					const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

					onTimerUpdate(formattedTime); // Notify parent component with the formatted time
					// Convert the elapsed time to mm:ss:SSS format
					const elapsedMinutes = Math.floor(elapsedTime / 60000);
					const elapsedSeconds = Math.floor((elapsedTime % 60000) / 1000);

					const formattedElapsedTime = `${elapsedMinutes
						.toString()
						.padStart(2, '0')}:${elapsedSeconds.toString().padStart(2, '0')}`;

					setNewFormattedTime(formattedElapsedTime);
					setIsSilenceDetected(Boolean(coachSays));

					if (elapsedTime >= 5000 && volume < 1 && isDidSpeak) {
						setIsSilenceDetected(true);
					}
					if (volume > 1) {
						setIsDidSpeak(true);
					}

					return newDuration;
				});
			}, 1000);
		} else if (duration === 0) {
			stopRecording(); // Automatically stop recording when duration reaches 0
		}

		return () => {
			if (interval) clearInterval(interval); // Clear interval on cleanup
		};
	}, [isRecording, duration, onTimerUpdate]);

	const sendAudioToServer = async (audioBlob: Blob) => {
		setChatLoading(true);
		const formData = new FormData();
		formData.append('audio', audioBlob, 'audio.wav');
		try {
			const response = await axios.post(`${baseUrl}/speechToText`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`
				}
			});
			valueHandler(response.data.transcription);
		} catch (error) {
			console.error('Error uploading file:', error);
			valueHandler('Error processing audio transcription.');
		}
	};

	const newIntRec = async () => {
		updateAudioRecorder(false);
		streamRef.current = await navigator.mediaDevices.getUserMedia({
			audio: true
		});
		if (streamRef.current) {
			intermediateRecorderRef.current = new MediaRecorder(streamRef.current);
			intermediateRecorderRef.current.start();
			intermediateRecorderRef.current.ondataavailable = handleIntermediateRecorderData;
		}
	};

	const stopIntRec = () => {
		if (intermediateRecorderRef.current) {
			intermediateRecorderRef.current.stop();
			if (intermediateRecorderRef.current.stream) {
				intermediateRecorderRef.current.stream.getTracks().forEach(track => track.stop());
			}
			intermediateRecorderRef.current.onstop = handleStopIntermediateRecorder;
		}
	};

	const stopRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop(); // Stop the media recorder
			if (isDidSpeak) {
				// this is necessary so that if we don’t say anything and try to send a message to the coach, the conversation ends
				setMicVisual(true);
				stopIntRec();
			}
			if (mediaRecorderRef.current.onstop) {
				mediaRecorderRef.current.onstop();
			}
			updateAudioRecorder(true);
			setIsRecording(false);
		}
	};

	const startRecording = async () => {
		setIsRecording(true);
		setMicVisual(false);
		setIsSilenceDetected(false);
		setIsDidSpeak(false);
		setNewFormattedTime('00:00');
		setStartTime(new Date());
		startTimeRef.current = Date.now();
		setDuration(5 * 60); // Reset duration to 5 minutes in seconds

		dispatch(setEnableVoice(true));
		try {
			streamRef.current = await navigator.mediaDevices.getUserMedia({
				audio: true
			});
			if (streamRef.current) {
				mediaRecorderRef.current = new MediaRecorder(streamRef.current);
				mediaRecorderRef.current.start();
				mediaRecorderRef.current.ondataavailable = handleMediaRecorderData;

				const audioContext = new (window.AudioContext || window.AudioContext)();
				const source = audioContext.createMediaStreamSource(streamRef.current);
				const analyser = audioContext.createAnalyser();
				analyser.fftSize = 2048;
				const bufferLength = analyser.frequencyBinCount;
				const dataArray = new Uint8Array(bufferLength);
				source.connect(analyser);

				updateVolume(analyser, dataArray, bufferLength);
			}
		} catch (error) {
			console.error('Error starting recording:', error);
		}
	};

	const updateVolume = (analyser: AnalyserNode, dataArray: Uint8Array, bufferLength: number) => {
		function update() {
			analyser.getByteFrequencyData(dataArray);
			let volume = 0;
			for (let i = 0; i < bufferLength; i++) {
				volume += dataArray[i];
			}
			volume /= bufferLength;
			if (audioThresholdRef.current < volume && Date.now() - (startTimeRef.current || 0) <= 150) {
				audioThresholdRef.current = volume < 0.5 ? 0.5 : volume;
			} else {
				if (-Infinity < volume && volume < audioThresholdRef.current) {
					if (!underOneStartTimestampRef.current) {
						underOneStartTimestampRef.current = Date.now();
					}
					const durationUnderOne: any = Date.now() - (underOneStartTimestampRef.current || 0);
					// Increase the timeout for detecting silence
					if (durationUnderOne >= 3000) {
						stopRecording();
						stopIntRec();
						underOneStartTimestampRef.current = null;
						return;
					}
				} else {
					underOneStartTimestampRef.current = null;
				}
			}
			if (mediaRecorderRef.current?.state === 'recording') setVolume(volume);
			requestAnimationFrame(update);
		}

		update();
	};

	useEffect(() => {
		// if coach stopped talking, start recording
		if (previousCoachSays === true && window.speechSynthesis.speaking === false && !isRecording) {
			startTimeRef.current = null;
			audioThresholdRef.current = 0;
			setTimeout(() => {
				startRecording();
			}, 100);
		}
		setPreviousCoachSays(coachSays);
	}, [coachSays]);

	window.addEventListener('beforeunload', () => {
		window.speechSynthesis.cancel();
	});

	window.addEventListener('beforeunload', () => {
		window.speechSynthesis.cancel();
	});

	return (
		<div className="micBox">
			{!coachSays && (
				<>
					{isRecording ? (
						<p className="micTitle-secondary">Listening</p>
					) : chatLoading ? (
						''
					) : !coachSays ? (
						<p className="micTitle-primary">Tap to start a response</p>
					) : (
						''
					)}
				</>
			)}
			{isRecording ? <p className="micTitle-primary">{newFormattedTime}</p> : ''}
			<button
				className="btn circle-btn"
				/* eslint-disable-next-line */
				onClick={chatLoading || coachSays ? () => {} : isRecording ? stopRecording : startRecording}>
				{!coachSays ? (
					chatLoading ? (
						<CircularProgress size={30} />
					) : (
						<div className={`${isRecording ? 'micImage micAnimation' : 'micImage'}`}>
							<img src={mike} alt="mike" className={isRecording ? 'recordingAnimation' : ''} />
						</div>
					)
				) : (
					<div className={`${coachSays ? 'coachImage coachAnimation' : ''}`}>
						<img src={coachResp} alt="coachResp" />
					</div>
				)}
			</button>
		</div>
	);
};

AudioRecorder.propTypes = {
	valueHandler: PropTypes.func.isRequired,
	onTimerUpdate: PropTypes.func.isRequired,
	updateAudioRecorder: PropTypes.func.isRequired
};

export default AudioRecorder;
