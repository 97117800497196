import React from 'react';
import './AdminLogin.css';

export const AdminLogin = () => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const autoLogin = () => {
		window.open(`${baseUrl}/auth/workos?action=signin&role=admin`, '_self');
	};
	// const dispatch = useDispatch();
	// useEffect(() => {
	// 	removeToken();
	// 	dispatch(clearUserDetails());
	// 	dispatch(clearSelectedUser());
	// 	dispatch(clearConversationId());
	// 	dispatch(clearSimulationStep());
	// 	dispatch(setRole(null));
	// 	dispatch(setRoleCategory(null));
	// 	dispatch(setSimulationStep(0));
	// 	dispatch(clearSelectedCompletedSimulationMessages());
	// 	autoLogin();
	// }, []);
	return (
		<div className="center">
			<button className="sign-in-button" onClick={autoLogin}>
				Sign In
			</button>
		</div>
	);
};
