import { useContext } from 'react';
import { ChatContext } from '../Contexts';
import { defaultFeedbackContent } from './constants';

const useBackToDefault = () => {
	const { setMessages, setSelectedConversationId, setFeedbackContent, setRecommendations } = useContext(ChatContext);
	return function backtoDefault() {
		setSelectedConversationId(undefined);
		setMessages([]);
		setFeedbackContent(defaultFeedbackContent);
		setRecommendations([]);
		// dispatch(clearConversationId())
	};
};

export default useBackToDefault;
