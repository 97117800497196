import { Box } from '@mui/material';
import React from 'react';
import PreparationHeader from '../../SimulationPreparation/PreparationHeader';
import ChatBox from '../../SimulationPreparation/ChatBox';

const SimulationPrepare = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any

	return (
		<>
			<Box
				sx={{
					width: '100%',
					px: '32px',
					'@media (max-width: 768px)': {
						px: '10px'
					}
				}}
			>
				<PreparationHeader
				// title="Simulation Preparation with Coach"
				// para = "Welcome to the simulation preparation phase. This is where you'll get ready for the upcoming scenario. Please follow these instructions carefully."
				/>
				<ChatBox />
			</Box>
		</>
	);
};

export default SimulationPrepare;
