import React from 'react';
import { Typography, Box, Link } from '@mui/material';

export const TermsOfUse = () => {
  return (
    <section>
      <div className="container">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom style={{fontFamily:"Raleway",fontWeight:"500"}}>
            Terms of Use
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            Welcome to Simsola.ai! By accessing or using our website (the &quot;Site&quot;), you agree to be bound by
            these terms of use (the &quot;Terms&quot;). If you do not agree to these Terms, please do not use the Site.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Use of Site
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            You may use the Site for lawful purposes only. You agree not to use the Site in any way that could damage,
            disable, overburden, or impair the Site, or interfere with any other party&apos;s use of the Site.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            All content on this Site, including text, graphics, logos, images, and software, is the property of
            Simsola.ai or its content suppliers and is protected by international copyright laws. You may not reproduce,
            distribute, or create derivative works based on any content on the Site without the express written
            permission of Simsola.ai.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            Simsola.ai will not be liable for any damages of any kind arising from the use of this Site, including but
            not limited to direct, indirect, incidental, punitive, and consequential damages.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            Simsola.ai reserves the right to modify these Terms at any time. Your continued use of the Site following
            any changes signifies your acceptance of the new Terms.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Governing Law
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            These Terms are governed by and construed in accordance with the laws of the jurisdiction in which
            Simsola.ai operates, without regard to its conflict of law principles.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom style={{fontFamily:"Raleway",fontWeight:"600",fontSize:"25px"}}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily:"IBM Plex Sans",fontSize:"18px"}}>
            If you have any questions about these Terms, please contact us at{' '}
            <Link href="mailto:info@simsola.ai">info@simsola.ai</Link>.
          </Typography>
        </Box>
      </div>
    </section>
  );
};
