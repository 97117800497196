import React from 'react';
import shieldIcon from '../../Assets/Images/Badges/image 9.png';
import inteIcon from '../../Assets/Images/Badges/image 10.png';
import cloudIcon from '../../Assets/Images/Badges/image 11.png';
import refreshIcon from '../../Assets/Images/Badges/image 12.png';
import enlargeIcon from '../../Assets/Images/Badges/image 13.png';
import { Box, Typography, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setBadgeSimulation } from '../../Helper/Redux/Action/userAction';
import { useNavigate } from 'react-router-dom';

const Badges = () => {
	const dispatch = useDispatch();
	const allIcons = [shieldIcon, inteIcon, cloudIcon, refreshIcon, enlargeIcon];
	const level = useSelector((state: any) => state.level) || 0;
	const simulations = useSelector((state: any) => state.cohortSimulation.simulations) || [];
	const navigate = useNavigate();
	const progressHandler = (id: any) => {
		dispatch(setBadgeSimulation(id));
		navigate('/progress');
	};
	// console.log(simulations);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
			<Typography sx={{ fontFamily: 'IBM Plex Sans !important', fontSize: '16px !important' }}>
				Simulation Levels Accomplished:
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
				{simulations.map((simulation: { scenario_name: any; scenario_id: any; badge: any; isPassed: any; }, index: React.Key | null | undefined) => {
					// Get the scenario details from the current simulation item
					const { scenario_name, scenario_id, badge, isPassed } = simulation;
					return (
						<Tooltip key={index} title={scenario_name} arrow>
							<img
								style={{
									width: '45px',
									height: '45px',
									filter: isPassed ? 'none' : 'grayscale(100%)', // Apply grayscale if isPassed is false
									opacity: isPassed ? 1 : 0.5, // Adjust opacity based on isPassed
									cursor: 'pointer'
								}}
								alt={`${scenario_name} badge`}
								src={badge?.data} // Use badge data as the source
								onClick={() => {
									if (isPassed) {
										progressHandler(scenario_id); // Only call if isPassed
									}
								}}
							/>
						</Tooltip>
					);
				})}
			</Box>
		</Box>
	);
};

export default Badges;
