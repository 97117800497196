import React, { useRef, useState } from 'react';
import { Chip } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const AudioCard = ({
	item: { voice, name },
	selectedVoiceModal,
	setSelectedVoiceModal
}: {
	item: { voice: string; name: string };
	selectedVoiceModal: string;
	setSelectedVoiceModal: (name: string) => void;
}) => {
	const audio = useRef<HTMLAudioElement>(new Audio(voice));
	const [isPlaying, setIsPlaying] = useState(false);

	const handlePlay = () => {
		if (isPlaying) {
			audio.current.pause();
			setIsPlaying(false);
		} else {
			audio.current.play();
			setIsPlaying(true);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '8px',
				cursor: 'pointer',
				borderRadius: '10px',
				gap: '6px',
				border: '1px solid rgba(224, 224, 224, 1)',
				background: selectedVoiceModal === name ? '#CFE9FD' : 'white'
			}}
		>
			<div onClick={() => setSelectedVoiceModal(name)}>{name}</div>
			<div onClick={handlePlay}>
				{isPlaying ? <PauseCircleIcon color="primary" /> : <PlayCircleIcon color="primary" />}
			</div>
		</div>
	);
};

export default AudioCard;
