/* eslint-disable react/no-unknown-property */
import React from 'react';

interface PropType {
	htmlColor: string;
}

// eslint-disable-next-line react/prop-types
const QuestionSymbol = ({ htmlColor }: PropType) => {
	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<g id="codicon:question">
					<path
						id="Vector"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.25 1.5C8.66414 1.5 6.18419 2.52723 4.35571 4.35571C2.52723 6.18419 1.5 8.66414 1.5 11.25C1.5 13.8359 2.52723 16.3158 4.35571 18.1443C6.18419 19.9728 8.66414 21 11.25 21C13.8359 21 16.3158 19.9728 18.1443 18.1443C19.9728 16.3158 21 13.8359 21 11.25C21 8.66414 19.9728 6.18419 18.1443 4.35571C16.3158 2.52723 13.8359 1.5 11.25 1.5ZM11.25 19.5C9.06196 19.5 6.96354 18.6308 5.41637 17.0836C3.86919 15.5365 3 13.438 3 11.25C3 9.06196 3.86919 6.96354 5.41637 5.41637C6.96354 3.86919 9.06196 3 11.25 3C13.438 3 15.5365 3.86919 17.0836 5.41637C18.6308 6.96354 19.5 9.06196 19.5 11.25C19.5 13.438 18.6308 15.5365 17.0836 17.0836C15.5365 18.6308 13.438 19.5 11.25 19.5ZM13.575 6.87C13.3159 6.60015 13.0045 6.38581 12.66 6.24C12.2265 6.06643 11.7617 5.98471 11.295 6C10.842 5.99177 10.3923 6.07864 9.975 6.255C9.6165 6.405 9.3 6.636 9.045 6.93C8.79659 7.20018 8.60301 7.51603 8.475 7.86C8.35599 8.20941 8.28042 8.57213 8.25 8.94H10.095C10.1035 8.70193 10.1793 8.47115 10.3137 8.27442C10.448 8.07769 10.6354 7.92307 10.854 7.8285C10.9989 7.7714 11.1545 7.74633 11.31 7.755C11.4442 7.73368 11.5808 7.73368 11.715 7.755C11.8382 7.80161 11.9506 7.87309 12.045 7.965C12.1492 8.05517 12.2313 8.16808 12.285 8.295C12.3464 8.43696 12.3771 8.59033 12.375 8.745C12.375 9.0045 12.3195 9.2595 12.21 9.495C12.1011 9.73933 11.9652 9.97076 11.805 10.185L11.28 10.815C11.1 11.01 10.92 11.22 10.755 11.43C10.595 11.639 10.4592 11.8655 10.35 12.105C10.2459 12.3411 10.1947 12.5971 10.2 12.855V13.845H12V13.11C12.0051 12.89 12.0616 12.6742 12.165 12.48C12.2883 12.2641 12.4287 12.0584 12.585 11.865L13.125 11.205C13.3216 10.9779 13.502 10.7373 13.665 10.485C13.8374 10.2277 13.9784 9.95076 14.085 9.66C14.1944 9.35176 14.2502 9.02707 14.25 8.7C14.2527 8.35923 14.2021 8.02014 14.1 7.695C13.9867 7.38527 13.8076 7.10379 13.575 6.87ZM10.2 14.745H11.955V16.5H10.2V14.745Z"
						fill={htmlColor}
					/>
				</g>
			</svg>
		</div>
	);
};

export default QuestionSymbol;
