import {
	Box,
	Button,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { AddCoachStudent } from './AddCoachStudent';
import SingleCohort from './SingleCohort';

interface Role {
	id: number;
	name: string;
}

const CoachStudent = () => {
	const [open, setOpen] = useState(false);
	const [allRoles, setAllRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState<Role | null>(null);
	const [isRefresh, setIsRefresh] = useState(false);
	const [userData, setUserData] = useState([]);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [singleCohortOpen, setSingleCohortOpen] = useState(false);
	const [selectedCohortId, setSelectedCohortId] = useState<string | number | null>(null);
	const [search, setSearch] = useState('');

	useEffect(() => {
		getRoles();
	}, [isRefresh]);

	useEffect(() => {
		if (selectedRole) {
			getUser();
		}
	}, [selectedRole, isRefresh]);

	const getUser = async () => {
		await axios
			.post(
				`${baseUrl}/api/admin/getuserByRole`,
				{ role: selectedRole },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(res => {
				if (res.data.success) {
					setUserData(res.data.users);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const getRoles = async () => {
		await axios
			.get(`${baseUrl}/api/admin/getall/role`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setAllRoles(res.data.roles);
					if (isInitialLoad) {
						const clientRole = res.data.roles.find((item: { name: string }) => item.name === 'client');
						setSelectedRole(clientRole || null);
						setIsInitialLoad(false);
					}
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const deleteHandler = async (userId: string, cohortId: string) => {
		await axios
			.delete(`${baseUrl}/api/admin/removeCohortUser/${userId}`, {
				headers: {
					Authorization: token
				},
				params: {
					cohortId: cohortId
				}
			})
			.then(res => {
				if (res.data.success) {
					getUser();
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
	};

	const filteredUserData = userData.filter(
		(item: { cohort: { id: string; name: string }[]; first_name: string; last_name: string }) =>
			item.cohort.length > 0 &&
			(item.first_name.toLowerCase().includes(search.toLowerCase()) ||
				item.last_name.toLowerCase().includes(search.toLowerCase()) ||
				item.cohort.some((cohort: { name: string }) => cohort.name.toLowerCase().includes(search.toLowerCase())))
	);

	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', flexWrap:"wrap" }}>
					<Typography variant="h6" sx={{minWidth:"350px"}}>Cohort User Management</Typography>
					<Box sx={{ display: 'flex', gap: '10px' , width:"40%", minWidth:"300px", justifyContent:"end" }}>
						<Box sx={{width:'180px'}}>
							<TextField name="search" placeholder='Type here to search' required value={search} onChange={inputHandler} fullWidth />
						</Box>
						<Select
							name="selectedRole"
							sx={{width:'120px'}}
							value={JSON.stringify(selectedRole) || ''}
							fullWidth
							onChange={(e: SelectChangeEvent<string>) => setSelectedRole(JSON.parse(e.target.value))}>
							{allRoles
								?.filter((item: { name: string }) => item.name?.toLowerCase() !== 'admin')
								.map((item: { name: string }, index: number) => (
									<MenuItem key={index} value={JSON.stringify(item)}>
										<Typography>{item?.name}</Typography>
									</MenuItem>
								))}
						</Select>
						<Button sx={{width:'50px'}} startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
							Add
						</Button>
					</Box>
				</Box>
				<TableContainer sx={{ borderRadius: '8px' }}>
					<Table>
						<TableHead
							sx={{
								background: '#CFE9FD',
								border: '1px solid rgba(224, 224, 224, 1)'
							}}>
							<TableRow>
								<TableCell align="left">User</TableCell>
								<TableCell align="left">Cohort</TableCell>
								<TableCell align="left">Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredUserData.length > 0 &&
								filteredUserData.flatMap(
									(items: {
										cohort: { id: string; name: string }[];
										firebase_id: string;
										first_name: string;
										last_name: string;
									}) =>
										items.cohort.map((cohort: { id: string; name: string }, cohortIndex: number) => (
											<TableRow
												sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
												key={`${items.firebase_id}-${cohortIndex}`}>
												<TableCell align="left">
													{items.first_name + (items.last_name ? ' ' + items.last_name : '')}
												</TableCell>
												<TableCell
													align="left"
													onClick={() => {
														setSelectedCohortId(cohort.id);
														setSingleCohortOpen(true);
													}}>
													{cohort.name}
												</TableCell>
												<TableCell align="left">
													<Button
														variant="outlined"
														size="small"
														startIcon={<DeleteIcon />}
														onClick={() => deleteHandler(items.firebase_id, cohort.id)}>
														Delete
													</Button>
												</TableCell>
											</TableRow>
										))
								)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<AddCoachStudent
					setOpen={setOpen}
					setIsRefresh={setIsRefresh}
					selectedRole={selectedRole}
					isRefresh={isRefresh}
				/>
			</Modal>
			<Modal open={singleCohortOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<SingleCohort setSingleCohortOpen={setSingleCohortOpen} selectedCohortId={selectedCohortId} />
			</Modal>
		</>
	);
};

export default CoachStudent;
