import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { clientRouteConfig } from './routeConfig'; // Adjust the import path as necessary
import getUser from '../../Helper/getUser';
import Badges from './Badges';


export const findRouteName = (path: any, routes: any, basePath = '') => {
	for (const route of routes) {
		const fullPath = basePath + route.path;
		if (fullPath === path) {
			return route.name;
		}
		if (route.children) {
			const subRouteName: any = findRouteName(path, route.children, fullPath);
			if (subRouteName) {
				return subRouteName;
			}
		}
	}
	return null;
};


const Breadcrumb = () => {
	const location = useLocation();
	const pathParts = location.pathname.split('/').filter(Boolean);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 747);

	let pathAccum = '';
	// const simulationStep = useSelector((state: any) => state.simulationStep);
    // console.log(sim ulationStep.simulationStep);
	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth > 747);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const breadcrumbs = pathParts.map(part => {
		pathAccum += `/${part}`;
		const name = findRouteName(pathAccum, clientRouteConfig) || part;
		return { path: pathAccum, name };
	});
	const user = getUser();
	return (
		<nav aria-label="breadcrumb">
			<ol className="breadcrumb">
				{/* {breadcrumbs.length > 0 && (
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        )} */}
				{breadcrumbs.map((crumb, index) => (
					<li key={crumb.path} className="breadcrumb-item">
						{crumb.name === 'Simulation' && user?.role === 'client' && isLargeScreen ? (
							<Badges />
						) : index < breadcrumbs.length - 1 ? (
							<Link to={crumb.path}>{crumb.name}</Link>
						) : (
							<span>{crumb.name}</span>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
