import React, { useState } from 'react';
import { Box, Tabs, Tab, useMediaQuery, useTheme } from '@mui/material';
import PromptTable from './PromptTable/PromptTable';
import ScenarioCreation from './CreatePrompt/ScenarioCreation';

const MainPrompt = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant={isSmallScreen ? 'fullWidth' : 'standard'}
        centered={!isSmallScreen}
      >
        <Tab label="View" />
        <Tab label="Creation" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {value === 0 && <PromptTable />}
        {value === 1 && <ScenarioCreation />}
      </Box>
    </Box>
  );
};

export default MainPrompt;
