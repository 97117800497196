const initialState = {
	userDetails: null,
	cohort: null,
	isAuthenticated: false
};
const intitialMic = {
	voiceStatus: false
};
const intitialRole = {
	roleName: null,
	stepCount: null
};
const initialId = {
	conversationId: null
};
const initialValues = {
	communication: null,
	collaboration: null,
	conflict_resolution: null,
	interpersonal: null
};
const initialStep = {
	step: 0
};

const initialSelectedUserByCoach = {
	userId: null
};

const initialselectedCompletedSimulation = {
	simulationMessages: null,
	simulationId: null
};
const intitialRoleCategory = {
	category: null
};
const initialCohortSimulationState = {
	simulations: []
};
const initialBadgeState = {
	badgeId: null
};
export const initialSelectedUserReducer = (state = initialSelectedUserByCoach, action: any) => {
	switch (action.type) {
		case 'SET_USER_ID':
			return {
				userId: action.payload
			};
		case 'CLEAR_USER_ID':
			return {
				userId: null
			};
		default:
			return state;
	}
};

export const userReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case 'SET_USER_DETAILS':
			return {
				...state,
				userDetails: action.payload,
				isAuthenticated: true
			};
		case 'CLEAR_USER_DETAILS':
			return {
				...state,
				userDetails: null,
				isAuthenticated: false,
				cohort: null
			};

		case 'SET_USER_COHORT':
			return {
				...state,
				cohort: action.payload
			};
		default:
			return state;
	}
};
export const voiceReducer = (state = intitialMic, action: any) => {
	switch (action.type) {
		case 'CHANGE_STATUS':
			return {
				...state,
				voiceStatus: action.payload
			};
		default:
			return state;
	}
};

export const roleReducer = (state = intitialRole, action: any) => {
	switch (action.type) {
		case 'SET_ROLE_NAME':
			return {
				...state,
				roleName: action.payload
			};
		case 'SET_STEP':
			return {
				...state,
				stepCount: action.payload
			};
		default:
			return state;
	}
};

export const simulationReducer = (state = initialValues, action: any) => {
	switch (action.type) {
		case 'SET_SIMULATION_VALUES':
			return {
				...state,
				simulationValues: action.payload
			};
		default:
			return state;
	}
};

export const conversationIdReducer = (state = initialId, action: any) => {
	switch (action.type) {
		case 'SET_CONVERSATION_ID':
			return {
				...state,
				conversationId: action.payload
			};
		case 'CLEAR_CONVERSATION_ID':
			return {
				...state,
				conversationId: null
			};
		default:
			return state;
	}
};
export const simulationStepReducer = (state = initialStep, action: any) => {
	switch (action.type) {
		case 'SET_SIMULATION_STEP':
			return {
				...state,
				simulationStep: action.payload
			};
		case 'CLEAR_SIMULATION_STEP':
			return {
				...state,
				simulationStep: null
			};
		default:
			return state;
	}
};

export const selectedCompletedSimulationReducer = (state = initialselectedCompletedSimulation, action: any) => {
	switch (action.type) {
		case 'SET_SIMULATION_DETAILS':
			return {
				simulationMessages: action.payload.messages,
				simulationId: action.payload.simulationId
			};
		case 'clearSimulationDetails':
			return {
				simulationMessages: null,
				simulationId: null
			};
		default:
			return state;
	}
};
export const roleCategoryReducer = (state = intitialRoleCategory, action: any) => {
	switch (action.type) {
		case 'SET_ROLE_CATEGORY':
			return {
				...state,
				category: action.payload
			};
		case 'CLEAR_ROLE_CATEGORY':
			return {
				...state,
				category: null
			};
		default:
			return state;
	}
};

export const lastConversationReducer = (state = { lastConversation: null }, action: any) => {
	switch (action.type) {
		case 'SET_LAST_CONVERSATION':
			return {
				...state,
				lastConversation: action.payload
			};
		case 'CLEAR_LAST_CONVERTSATION':
			return {
				...state,
				lastConversation: null
			};
		default:
			return state;
	}
};

export const levelReducer = (state = 0, action: any) => {
	switch (action.type) {
		case 'SET_LEVEL':
			return action.payload;

		case 'CLEAR_LEVEL':
			return 0;

		default:
			return state;
	}
};
export const cohortSimulationReducer = (state = initialCohortSimulationState, action: { type: any; payload: any }) => {
	switch (action.type) {
		case 'SET_COHORT_SIMULATION':
			return {
				...state,
				simulations: action.payload
			};

		case 'CLEAR_COHORT_SIMULATION':
			return {
				...state,
				simulations: []
			};

		default:
			return state;
	}
};
export const badgeSimulationReducer = (state = initialBadgeState, action: { type: any; payload: any }) => {
	switch (action.type) {
		case 'SET_BADGE_SIMULATION':
			return {
				...state,
				badgeId: action.payload // Set the id directly from the action payload
			};

		case 'CLEAR_BADGE_SIMULATION':
			return {
				...state,
				badgeId: null // Clear the badgeId by setting it to null
			};

		default:
			return state;
	}
};

export const cohortReducer = (
	state = {
		selectedCohort: null
	},
	action: { type: any; payload: any }
) => {
	switch (action.type) {
		case 'SET_SELECTED_COHORT':
			return {
				...state,
				selectedCohort: action.payload
			};
		case 'CLEAR_SELECTED_COHORT':
			return {
				...state,
				selectedCohort: null
			};
		default:
			return state;
	}
};
