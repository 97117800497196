/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useState } from 'react';
import { ReactComponent as ShowCommentForm } from '../../Assets/Images/ShowCommentForm.svg';
import sendComment from '../../Assets/Images/sendComment.svg';
import coachPfp from '../../Assets/Images/profilePictures/coachProfile.png';
import { ChatContext } from '../../Contexts';

import { convertTimestampTo12Hour, getFeedbacksOfChat, getRecommendationsOfChat } from '../../Helper/';

import { IChat, IComment } from '../../types';

// import dots from '../../Assets/Images/doticon.png';
import getUser from '../../Helper/getUser';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getToken } from '../../Helper/GetToken';

interface IChatProps {
	message: IChat;
	index: number;
}

const ChatMessage: React.FC<IChatProps> = ({ message, index }) => {
	const { selectedConversationId, setFeedbackContent, setRecommendations, setMessages } = useContext(ChatContext);
	const [isVisibleCommentForm, setIsVisibleCommentForm] = useState(false);
	const [commentText, setCommentText] = useState('');
	const user = getUser();
	const token = getToken();
	let tempUserId = user?.id;
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const conversationId = useSelector((state: any) => state.conversationId.conversationId);
	if (selectedUserId) tempUserId = selectedUserId;
	// Handling the message click to display the feedbacks and recommendations of that message

	const handleMsgClick = async () => {
		if (message.messageId && message.messageType === 'client') {
			try {
				if (selectedConversationId) {
					const listFeedbacks = await getFeedbacksOfChat(
						'feedback',
						tempUserId,
						selectedConversationId,
						message.messageId
					);
					const listRecommendations = await getRecommendationsOfChat(
						'recommendation',
						tempUserId,
						selectedConversationId,
						message.messageId
					);
					listFeedbacks && setFeedbackContent(listFeedbacks);
					listRecommendations && setRecommendations(listRecommendations);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const sendCommentHandler = async () => {
		if (commentText.trim().length > 0 && message && message.messageId) {
			const commentData = {
				userId: selectedUserId,
				conversationId: conversationId,
				coachId: user?.id,
				text: commentText
			};
			await axios
				.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/addComment/${message.messageId}`, commentData, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data.status === 'success') {
						setCommentText('');
						setMessages((prevMessages: IChat[]) => {
							const updatedMessages = [...prevMessages];
							if (Array.isArray(updatedMessages[index].comments)) {
								updatedMessages[index].comments = [
									...(updatedMessages[index].comments as IComment[]),
									res.data.comment
								];
							} else {
								updatedMessages[index].comments = [res.data.comment];
							}
							return updatedMessages;
						});
					}
				})
				.catch(e => {
					console.log(e);
				});
		}
	};

	return (
		<div>
			{message.messageType == 'coach' ? (
				<div style={{ display: 'flex' }}>
					<img
						src={coachPfp}
						style={{
							width: '24px',
							height: '24px',
							marginRight: '5px',
							marginBottom: '5px'
						}}
					/>
					<div>Coach</div>
				</div>
			) : (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div style={{ marginRight: '5px' }}>You</div>
					{/* display the picture that the user has
            <img src={user?.picture} style={{ width: "24px", height: "24px", marginBottom: "5px"}} />*/}
					<div
						style={{
							width: '24px',
							height: '24px',
							borderRadius: '50%',
							backgroundColor: '#82D1FE',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '5px',
							color: '#161616',
							textTransform: 'uppercase',
							fontSize: '14px'
						}}
					>
						{user?.name ? user.name[0].toUpperCase() : ''}
					</div>
				</div>
			)}
			<div className={message.messageType == 'coach' ? 'receive-box' : 'sender-box'}>
				<div onClick={handleMsgClick} className={`msg ${user?.role === 'coach' ? 'transparentMsg' : ''}`}>
					{/* <span>{message.message}</span> */}
					<span dangerouslySetInnerHTML={{ __html: message.message.replace(/\n/g, '<br/>') }} />

					{user?.role === 'coach' && message.messageType === 'client' && (
						<div className={`comment-wrapper ${isVisibleCommentForm ? 'active' : ''}`}>
							<ShowCommentForm className="commentIcon" onClick={() => setIsVisibleCommentForm(!isVisibleCommentForm)} />
						</div>
					)}
				</div>
				{message.messageType == 'client' &&
					message.comments &&
					message.comments.length > 0 &&
					message.comments.map(comment => (
						<div key={comment.commentId} className="comment-block">
							<span className="comment">{comment.text}</span>
						</div>
					))}
				{isVisibleCommentForm ? (
					<div className="send-comment">
						<input
							placeholder="Add a comment"
							onChange={event => setCommentText(event.target.value)}
							value={commentText}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									sendCommentHandler();
								}
							}}
						/>
						<img src={sendComment} alt="sendComment" onClick={sendCommentHandler} />
					</div>
				) : (
					<p>{convertTimestampTo12Hour(message.time)}</p>
				)}
			</div>
		</div>
	);
};

export default ChatMessage;
