import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getToken, removeToken } from './GetToken';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearAuthStatus,
	clearUserDetails,
	setAuthStatus,
	setUserCohort,
	setUserDetails
} from './Redux/Action/userAction';
import { setUser } from '@sentry/react';

interface ProtectedRouteProps {
	children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const dispatch = useDispatch();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const isAuthenticated = useSelector((state: { auth: { isAuthenticated: boolean } }) => state.auth.isAuthenticated);

	// Initialize AbortController
	const abortController = new AbortController();
	const verifyHandler = async (retryCount = 0) => {
		const token = getToken();
		if (token) {
			try {
				const res = await fetch(`${baseUrl}/verify/${token}`, {
					method: 'GET',
					headers: {
						Authorization: token,
						'Content-Type': 'application/json'
					},
					signal: abortController.signal
				});
				if (!res.ok) {
					handleUnauthenticated();
					// throw new Error(`HTTP error! status: ${res.status}`);
				}
				const data = await res.json();
				if (data.success) {
					dispatch(setAuthStatus(true));
					dispatch(setUserDetails(data.user));
					setUser({
						id: data.user.id,
						name: data.user.name,
						email: data.user.email,
						role: data.user.role
					});
					const userRes = await fetch(`${baseUrl}/getUser/${data.user.id}`, {
						method: 'GET',
						headers: {
							Authorization: token,
							'Content-Type': 'application/json'
						},
						signal: abortController.signal
					});
					if (!userRes.ok) {
						handleUnauthenticated();
						// throw new Error(`HTTP error! status: ${userRes.status}`);
					}
					const userData = await userRes.json();
					if (userData && userData.cohort) {
						dispatch(setUserCohort(userData.cohort.id));
					}
					setIsLoading(false);
				} else {
					handleUnauthenticated();
				}
			} catch (e: any) {
				console.error('Verification error:', e);
				if (retryCount < 3 && e.name !== 'AbortError') {
					verifyHandler(retryCount + 1);
				} else {
					if (e.name === 'TypeError' && e.message.includes('Load failed')) {
						console.error('Load error:', e);
					} else {
						handleUnauthenticated();
					}
				}
				throw new Error(e); // sentry error handling
			}
		} else {
			handleUnauthenticated();
		}
	};

	const handleUnauthenticated = () => {
		removeToken();
		dispatch(clearAuthStatus());
		dispatch(clearUserDetails());
		setIsLoading(false);
		navigate('/');
	};

	useEffect(() => {
		// if (!isAuthenticated) {
		// 	verifyHandler();
		// } else {
		// 	setIsLoading(false);
		// }
		verifyHandler();
	}, [isAuthenticated, navigate]);

	if (isLoading) {
		return <div>Loading...</div>; // Or any other loading indicator
	}

	return isAuthenticated ? <>{children}</> : null;
};

// Define PropTypes for the component
ProtectedRoute.propTypes = {
	children: PropTypes.node.isRequired
};

export default ProtectedRoute;
