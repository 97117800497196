import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './config/firebase';

import './index.css';

import * as Sentry from '@sentry/react';
import 'react-quill/dist/quill.snow.css';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
	// integrations: [new Integrations.BrowserTracing()],
	environment: process.env.REACT_APP_SENTRY_ENV || 'local',
	tracesSampleRate: 1.0,
	// tracePropagationTargets: ['localhost', 'https://dev.simsola.dev'],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	profilesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
