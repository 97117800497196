import React, { useEffect, useState } from 'react';
import { getToken } from '../../../Helper/GetToken';
import axios from 'axios';
import {
	Box,
	Button,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CreateCategory from '../Simulation/CreateCategory';
import EditCategory from '../Simulation/EditCategory';
interface MemberProps {
	imageData: unknown[];
	parent_id: number;
	setShowMember: (showMember: boolean) => void;
}

export type CategoryType = {
	scenario_name: string;
	parent_id: number | null;
	category: string;
	picture: { id: string; name: string; data: string };
	description: string;
	scenario_id: number;
	role_name: string;
	video_url?: string | null;
	voice_modal?: string | null;
	badge?:{ id: string; name: string; data: string }
};

const MemberTable: React.FC<MemberProps> = ({ imageData, parent_id, setShowMember }) => {
	const [tableData, setTableData] = useState([]);
	const [categoryData, setCategoryData] = useState<CategoryType>({
		scenario_name: '',
		parent_id: parent_id,
		category: '',
		picture: { id: '', name: '', data: '' },
		description: '',
		scenario_id: 0,
		role_name: '',
		video_url: null,
		voice_modal: null,
		badge: { id: '', name: '', data: '' },
	});
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	useEffect(() => {
		if (isRefresh) {
			getScenarios();
		}
	}, [isRefresh]);
	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				},
				params: { id: parent_id }
			})
			.then(res => {
				if (res.data) {
					setTableData(res.data);
					setIsRefresh(false);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};
	const deleteHandler = async (id: number) => {
		await axios
			.delete(`${baseUrl}/api/scenario/deleteScenario/${id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(() => {
				setIsRefresh(!isRefresh);
			})
			.catch(e => {
				console.log(e);
			});
	};
	const editHandler = (data: CategoryType) => {
		setCategoryData({
			scenario_name: data?.scenario_name || '',
			parent_id: data?.parent_id ?? null,
			category: data?.category || '',
			picture: data?.picture || { id: '', name: '', data: '' },
			description: data?.description || '',
			scenario_id: data?.scenario_id || 0,
			role_name: data?.role_name || '',
			video_url: data?.video_url ?? null,
			voice_modal: data?.voice_modal ?? null,
			badge: data?.badge || { id: '', name: '', data: '' },
		});
		setEditOpen(true);
	};

	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Button startIcon={<ArrowBackIosIcon />} onClick={() => setShowMember(false)} color="secondary">
						Back
					</Button>
					<Box>
						<Typography variant="h6">Simulation Person</Typography>
					</Box>
					<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
						Add
					</Button>
				</Box>
				<Box>
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow sx={{ textWrap: 'nowrap' }}>
									<TableCell align="left">Scenario Id</TableCell>
									<TableCell align="left">Scenario Name</TableCell>
									<TableCell align="left">Category</TableCell>
									<TableCell align="left">Description</TableCell>
									<TableCell align="left">Role</TableCell>
									<TableCell align="left">Profile</TableCell>
									<TableCell align="left">Edit</TableCell>
									<TableCell align="left">Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(tableData) &&
									tableData.length > 0 &&
									tableData.map((items: CategoryType, index: number) => {
										return (
											<>
												<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
													<TableCell align="left">{items.scenario_id}</TableCell>
													<TableCell align="left">{items.scenario_name}</TableCell>
													<TableCell align="left">{items.category}</TableCell>
													<TableCell align="left">{items.description}</TableCell>
													<TableCell align="left">{items?.role_name || '-'}</TableCell>
													<TableCell align="left">
														<img alt="loading" src={items?.picture?.data} style={{ width: '24px', height: '24px' }} />
													</TableCell>
													<TableCell align="left">
														<Button
															variant="outlined"
															color="secondary"
															size="small"
															startIcon={<EditIcon />}
															onClick={() => editHandler(items)}
														>
															Edit
														</Button>
													</TableCell>
													<TableCell align="left">
														<Button
															variant="outlined"
															size="small"
															startIcon={<DeleteIcon />}
															onClick={() => deleteHandler(items.scenario_id)}
														>
															Delete
														</Button>
													</TableCell>
												</TableRow>
											</>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			<Modal
				open={open}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<CreateCategory setOpen={setOpen} imageData={imageData} setIsRefresh={setIsRefresh} parent_id={parent_id} />
			</Modal>
			<Modal
				open={editOpen}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<EditCategory
					setEditOpen={setEditOpen}
					imageData={imageData}
					setIsRefresh={setIsRefresh}
					categoryData={categoryData}
				/>
			</Modal>
		</>
	);
};

export default MemberTable;
