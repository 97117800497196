import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Avatar,
	Box,
	Button,
	IconButton,
	Radio,
	RadioGroup,
	Skeleton,
	Snackbar,
	Tabs,
	Typography,
	tabsClasses
} from '@mui/material';

import './AnalyzeMeetings.css';
import FeedbackCard from '../Feedback/FeedbackCard';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tab, { tabClasses } from '@mui/material/Tab';
import axios from 'axios';
import { getToken } from '../../Helper/GetToken';

interface SummaryResponse {
	id: string;
	summary: string;
	analytics: AnalyticsItem[];
}

interface AnalyticsItem {
	scenario_id: string;
	analytics: {
		[key: string]: Feedback;
	};
}

interface Feedback {
	reason: string;
	score: number;
	recommendation: string;
}

interface CategoryConvItem {
	scenario_name: string;
	scenario_id: string;
	hasMeetingPrompt: boolean;
}
interface PropType {
	summaryResponse?: SummaryResponse;
	setSummaryResponse?: React.Dispatch<React.SetStateAction<SummaryResponse | undefined>>;
	setStep?: (step: string) => void;
	categoryConv?: CategoryConvItem[];
}

const TabItem = styled(Tab)(({ theme }) => ({
	position: 'relative',
	borderRadius: '8px',
	textAlign: 'center',
	transition: 'all .5s',
	padding: '8px 10px',
	color: '#555555',
	height: 'auto',
	margin: '10px 0',
	float: 'none',
	fontSize: '12px',
	fontWeight: '700',
	[theme.breakpoints.up('md')]: {
		minWidth: 120
	},
	[`&.${tabClasses.selected}, &:hover`]: {
		color: '#000000',
		borderColor: '#1C75BA',
		backgroundColor: '#E0F1FA',
		boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)'
	},
	[`&.${tabClasses.disabled}`]: {
		borderColor: '#9E9E9E'
	}
}));
const Summary = ({ summaryResponse, setSummaryResponse, setStep, categoryConv }: PropType) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const meetingId = searchParams.get('meeting');
	const [analyticsLoading, setAnalyticsLoading] = useState(false);
	const [analytics, setAnalytics] = useState([]);
	const [selectedAttendee, setSelectedAttendee] = useState<string>('');
	const [selectedCategory, setSelectedCategory] = useState<string>('');
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'error' | 'success' | 'warning' | undefined;
		message?: string;
	}>({
		open: false
	});

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};
	const token = getToken();
	const styles = {
		MainBox: {
			height: 'calc(100vh - 70px)'
		},
		Box: {
			padding: '32px',
			margin: 'auto'
		},
		Header: {
			fontSize: '24px',
			gap: '8px',
			fontWeight: '700',
			mb: '18px'
		},
		Attendee: {
			display: 'flex',
			fontWeight: '500',
			mt: '10px'
		},
		FooterRecommendations: {
			width: '100%',
			padding: '0 32px 32px 32px'
		}
	};

	const [expanded, setExpanded] = useState(true);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		if (
			summaryResponse &&
			Array.isArray(summaryResponse?.analytics) &&
			summaryResponse?.analytics?.length > 0 &&
			summaryResponse?.analytics.some(items => items.scenario_id === selectedCategory) &&
			selectedAttendee
		) {
			const array: any = [];

			summaryResponse.analytics
				.filter(items => items.scenario_id === selectedCategory)
				.forEach((items: any) => {
					const feedback = items.analytics[selectedAttendee];
					if (feedback) {
						Object.keys(feedback).forEach(item => {
							array.push({
								name: item,
								description: feedback[item]?.reason,
								score: (100 * (feedback[item]?.score + 1)) / 4,
								dimension: item,
								recommendation: feedback[item]?.recommendation
							});
						});
					}
				});

			setAnalytics(array.sort((a: any, b: any) => a.score - b.score));
		} else {
			setAnalytics([]);
		}
	}, [summaryResponse, selectedAttendee, selectedCategory]);

	const getAnalyticsForUser = async () => {
		try {
			setAnalyticsLoading(true);

			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/meeting/analytics`,
				{
					meetingId: summaryResponse?.id,
					scenario_id: selectedCategory
				},
				{
					headers: {
						Authorization: token
					}
				}
			);
			if (response.data.success) {
				setSummaryResponse && setSummaryResponse(response.data.data);
			} else if (response.data?.changeLLM) {
				console.log(response.data);
				try {
					const responseGemini = await axios.post(
						`${process.env.REACT_APP_BACKEND_BASE_URL}/v2/meeting/analytics`,
						response.data,
						{
							headers: {
								Authorization: token
							}
						}
					);
					if (responseGemini.data.success) {
						setSummaryResponse && setSummaryResponse(responseGemini.data.data);
					}
				} catch (error) {
					console.error('Error LLM V2', error);
				}
			} else {
				setOpenAlert({
					open: true,
					type: 'error',
					message: 'Something went wrong!'
				});
			}
		} catch (error) {
			console.error('Error uploading file:', error);
		} finally {
			setAnalyticsLoading(false);
		}
	};

	useEffect(() => {
		if (!summaryResponse && !meetingId) {
			setStep && setStep('upload');
			navigate('/analyze-meetings?tab=upload');
		}
		setSelectedCategory('');
		setSelectedAttendee('');
	}, [meetingId]);

	return (
		<Box sx={{ ...styles.MainBox }}>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			<Box sx={{ ...styles.Box }}>
				<Typography sx={{ ...styles.Header }}>Meeting Summary</Typography>
				<Tabs
					variant="scrollable"
					allowScrollButtonsMobile
					value={selectedCategory}
					onChange={(e, newValue) => {
						setSelectedCategory(newValue);
						setSelectedAttendee('');
					}}
					sx={{
						[`& .${tabsClasses.indicator}`]: {
							display: 'none'
						},
						gap: '8px'
					}}
				>
					{categoryConv &&
						categoryConv.map(
							(item: { scenario_name: string; scenario_id: string; hasMeetingPrompt: boolean }, index: number) => (
								<TabItem
									disabled={!item?.hasMeetingPrompt}
									sx={{ border: '2px solid #1C75BA', mr: '8px', height: '32px' }}
									key={index}
									disableRipple
									label={item.scenario_name}
									value={item.scenario_id}
								/>
							)
						)}
				</Tabs>
				<Box sx={{ fontSize: '1rem', width: '70%' }}>
					{summaryResponse?.summary?.replaceAll('*', '').replaceAll('\\n', '\n')}
				</Box>

				{summaryResponse &&
				Array.isArray(summaryResponse?.analytics) &&
				selectedCategory &&
				summaryResponse?.analytics.length > 0 ? (
					<Box sx={{ ...styles?.Attendee }}>
						<Box sx={{ fontSize: '14px', width: '70%' }}>
							<RadioGroup value={selectedAttendee} onChange={e => setSelectedAttendee(e.target.value)}>
								{summaryResponse.analytics
									.filter((itemss: { scenario_id: string }) => itemss.scenario_id === selectedCategory)
									.map(items =>
										Object.keys(items.analytics).map((item, index) => (
											<div
												key={index}
												style={{
													display: 'flex',
													gap: '8px',
													alignItems: 'center'
												}}
											>
												<Radio value={item} />
												<Avatar
													sx={{
														width: '32px',
														height: '32px',
														background: '#2ABACD'
													}}
												>
													{item.slice(0, 1)}
												</Avatar>
												<Typography>{item}</Typography>
											</div>
										))
									)}
							</RadioGroup>
						</Box>
					</Box>
				) : (
					''
				)}

				{analyticsLoading && <Skeleton height={200} />}

				{!analyticsLoading &&
					summaryResponse &&
					selectedCategory &&
					(!Array.isArray(summaryResponse?.analytics) ||
						(Array.isArray(summaryResponse?.analytics) &&
							!summaryResponse.analytics.some(
								(itemss: { scenario_id: string }) => itemss.scenario_id === selectedCategory
							))) && (
						<Button
							sx={{ marginTop: '16px' }}
							variant="contained"
							onClick={getAnalyticsForUser}
							disabled={!selectedCategory}
						>
							Attendee Analytics
						</Button>
					)}

				{selectedAttendee && (
					<Box className="meeting-feedback-wrapper">
						{analytics?.length > 0 &&
							analytics?.map((analytics, key: number) => {
								return (
									<FeedbackCard key={`feedback_${key}`} feedback={analytics} selectedCategory={+selectedCategory} />
								);
							})}
					</Box>
				)}
			</Box>

			{selectedAttendee && (
				<Box sx={{ ...styles.FooterRecommendations }}>
					<Accordion expanded={expanded} onChange={toggleExpand}>
						<AccordionSummary
							expandIcon={<IconButton>{expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</IconButton>}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Recommendations</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{analytics?.length > 0
								? analytics
										.slice(0, 2)
										?.map((recommendation: { dimension: string; recommendation: string }, key: number) => (
											<Accordion
												sx={{
													marginBottom: '10px',
													border: '1px solid #1C75BA',
													borderRadius: '8px'
												}}
												key={key}
												defaultExpanded={key === 0}
											>
												<AccordionSummary
													sx={{ margin: '0' }}
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1-content"
													id="panel1-header"
												>
													<Typography color={'#1C75BA'}>{recommendation?.dimension}</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Typography sx={{ fontSize: '14px' }}>{recommendation?.recommendation}</Typography>
												</AccordionDetails>
											</Accordion>
										))
								: 'There is no recommendations for this meeting.'}
						</AccordionDetails>
					</Accordion>
				</Box>
			)}
		</Box>
	);
};

export default Summary;
